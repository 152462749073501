import concat from 'lodash/concat';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import { createSelector } from 'reselect';

import { ITEM_TYPES as types, V3_PAYMENT_TYPES } from '../constants/ledger';
import * as paymentMethods from '../constants/paymentMethods';
import { PROMO_CODE_MAPPING } from '../constants/queryParams';
import {
  getSelectedAddons,
  getSelectedAddonBundles,
  getSelectedAddonIds,
  getSelectedAddonBundleIds,
} from './addons';
import {
  getClient,
  getIsBundlePartnerFlow,
  getIsPartnerFlow,
  getIsSpotifyPartnerFlow,
  getIsUnifiedActivationFlow,
  getIsVerizonDssFlow,
  getQuery,
} from './flow';
import { getIsDisneySuperBundle } from './plan';
import { getFilteredPlans, getPlanOptions } from './plans';
import { getControlText } from './siteconfig';
import { getSubscription } from './subscription';
import {
  getUser,
  getSelectedPlan,
  getIsLoggedInActiveSubscriber,
  getIsPrepaidForSelectedPlan,
} from './user';
import { getIsIneligible } from './warning';
import { getSubscriberId } from './sprintV2';
import { BADGE_TYPES } from '../constants/badge';
import {
  DUO_BASIC_COMPONENT_ID,
  DUO_PREMIUM_COMPONENT_ID,
  NOADS_LIVE_DISNEY_ESPN_ID,
  MAX_BUNDLE_NOADS_COMPONENT_ID,
  TRIO_PREMIUM_COMPONENT_ID,
} from '../constants/plans';
import { getIsLegalTaxDisclaimerFeatureEnabled } from './featureFlag';

const getPayment = state => state.payment;

export const getIsLedgerLoading = state => state.ledger.loading;

export const getLedger = state =>
  createSelector(getSelectedPlan, selectedPlan => {
    if (isArray(get(state, 'ledger.ledger'))) {
      const selectedPlanIndex = getPlanOptions(state, selectedPlan)
        .map(o => o.subIdentifier)
        .indexOf(selectedPlan.subIdentifier);
      return get(state, `ledger.ledger[${selectedPlanIndex}]`, null);
    }
    return get(state, 'ledger.ledger', null);
  })(state);

export const shouldDisplayBundleTerms = createSelector(getSelectedPlan, plan =>
  getIsDisneySuperBundle(plan)
);

export const shouldDisplayChangePlan = createSelector(
  getFilteredPlans,
  plans => plans.length > 1
);

export const shouldDisplayTermsLink = (
  legalActionText,
  legalBody,
  type,
  displayChangePlan,
  displayBundleTerms
) =>
  // Return true if the following is true:
  // legalActionText AND legalBody exist
  // AND
  // item is not base bundle OR there is no plan select OR item is a Disney Super Bundle
  // Added the !! to ensure a boolean is returned in all cases
  !!(
    legalActionText &&
    legalBody &&
    (type !== types.BASE_BUNDLE || !displayChangePlan || displayBundleTerms)
  );

export const getLedgerInvoices = createSelector([getLedger], ledger =>
  get(ledger, 'invoices', null)
);

// TODO WEB-19545: Remove id and type arguments once ids and types are handled server-side
export const createLedgerItem = (
  item,
  { id, type, planNameOverrides = {} } = {}
) => ({
  id: item.id || id,
  type,
  name: planNameOverrides[item.id || id] || item.name,
  description:
    type === types.BASE_BUNDLE &&
    [
      DUO_PREMIUM_COMPONENT_ID,
      MAX_BUNDLE_NOADS_COMPONENT_ID,
      NOADS_LIVE_DISNEY_ESPN_ID,
      TRIO_PREMIUM_COMPONENT_ID,
    ].includes(id)
      ? 'Ads will be served in select live and linear content'
      : get(item, 'promotions[0].displayValue', null),
  showDuoBundleDisclaimer: [
    DUO_BASIC_COMPONENT_ID,
    DUO_PREMIUM_COMPONENT_ID,
  ].includes(id),
  price: get(item, 'price.displayValue', null),
  breakdown: item.items.map(breakdownItem => ({
    name: breakdownItem.name,
    price: get(breakdownItem, 'price.displayValue', null),
    promotions: breakdownItem.promotions.map(
      promotion => promotion.displayValue
    ),
  })),
  legalActionText: get(item, 'additionalInfo.link', null),
  legalBody: get(item, 'additionalInfo.body', []).map(
    paragraph => paragraph.value
  ),
  savings: null,
  announcement: null,
  promotions: get(item, 'promotions', []),
  badges: get(item, 'badges', []),
});

export const getLedgerPlanNameOverrides = state =>
  getControlText(state, 'ledger.plan_names');

export const getLedgerItems = createSelector(
  [
    getLedger,
    getLedgerPlanNameOverrides,
    getSelectedPlan,
    getSelectedAddonIds,
    getSelectedAddonBundleIds,
  ],
  (
    ledger,
    planNameOverrides,
    selectedPlan,
    selectedAddonIds,
    selectedAddonBundleIds
  ) => {
    if (!ledger) {
      return [];
    }

    const {
      subscription: { plan, addOns = [] },
    } = ledger;

    const selectedAddonIdsCopy = [...selectedAddonIds];
    const selectedAddonBundleIdsCopy = [...selectedAddonBundleIds];

    // TODO WEB-19545: Remove comment below once ids are handled server-side
    // The v4 ledger endpoint returns Splat productIds rather than the old Ballyhoo ids.
    // Actions like changing the plan or removing add-ons and add-on bundles rely on Ballyhoo ids and types,
    // so we inject those back into the ledger.
    // A longer term fix will be implemented with WEB-19545 that should fully remove the need to
    // inject ids in this manner.
    return concat(
      createLedgerItem(plan, {
        // TODO WEB-19545: Clean-up logic below once ids are handled server-side
        id: selectedPlan.id,
        type: types.BASE_BUNDLE,
        planNameOverrides,
      }),
      addOns.map(addOn =>
        createLedgerItem(addOn, {
          // TODO WEB-19545: Clean-up logic below once ids are handled server-side
          // Only add-on bundles have more than 1 entitlement.
          // Order of add-ons/add-on bundles in addOns should match the order of selectedAddonIds/selectedAddonBundleIds
          ...(addOn.entitlements?.length > 1
            ? {
                id: selectedAddonBundleIdsCopy.shift(),
                type: types.ADDON_BUNDLE,
              }
            : { id: selectedAddonIdsCopy.shift(), type: types.ADDON }),
          planNameOverrides,
        })
      )
    );
  }
);

export const getIsDueToday = createSelector([getLedger], ledger =>
  get(ledger, 'invoices[0].isToday', false)
);

export const getLedgerBadgeType = key => {
  if (key === 'prepaid' || key === 'student') {
    return BADGE_TYPES.LIGHT_GRAY_WITH_BLUE;
  }
  return BADGE_TYPES.LIGHT_GRAY;
};

export const getLedgerSummary = createSelector(
  [
    getIsPartnerFlow,
    getLedgerInvoices,
    getIsDueToday,
    getIsLegalTaxDisclaimerFeatureEnabled,
  ],
  (isPartnerFlow, invoices, isDueToday, isDisclaimer) => {
    if (isPartnerFlow || !invoices) {
      return null;
    }

    return {
      name: 'Total Due Today',
      price: isDueToday
        ? get(invoices[0], 'total.displayValue', '$0.00')
        : '$0.00',
      amount: isDueToday ? get(invoices[0], 'total.amount', 0) : 0,
      disclaimer: isDisclaimer ? 'plus applicable taxes' : '',
    };
  }
);

export const getLedgerNotices = createSelector([getLedger], ledger =>
  get(ledger, 'subscription.notices')
);

export const getLedgerTerms = createSelector([getLedger], ledger =>
  get(ledger, 'legal')
);

export const getLedgerMonthlyDueAmount = createSelector([getLedger], ledger =>
  get(ledger, 'subscription.recurringTotals[0].money.amount', 0)
);

export const getLedgerDueTodayAmount = createSelector(
  [getLedgerSummary],
  summary => get(summary, 'amount', 0)
);

// TODO WEB-9655: Remove
// Converts v2 addon to a v3 ledger request object.
const convertAddonToV3 = ({ componentId, programId, discountIds }) => ({
  id: componentId,
  programId,
  discountIds,
});

// TODO WEB-9655: Remove
// Converts v2 addon bundle to a v3 ledger request object.
const convertAddonBundleToV3 = ({ bundleId, programId, discountIds }) => ({
  id: bundleId,
  programId,
  discountIds,
  components: [],
});

// TODO WEB-9655: Remove
const getV3AllSelectedAddonRequests = createSelector(
  [getSelectedAddons, getSelectedAddonBundles],
  (addons, addonBundles) => ({
    addOns: addons
      ? addons.map(({ requestObject }) => convertAddonToV3(requestObject))
      : [],
    addOnBundles: addonBundles
      ? addonBundles.map(({ requestObject }) =>
          convertAddonBundleToV3(requestObject)
        )
      : [],
  })
);

export const getV3PromotionNode = createSelector([getQuery], query =>
  Object.keys(PROMO_CODE_MAPPING)
    .map(
      key =>
        query[key] && {
          code: query[key],
          type: PROMO_CODE_MAPPING[key],
        }
    )
    .find(value => value != null)
);

// TODO WEB-9655: Remove
const getV3SubscriptionNode = createSelector(
  [
    getSubscription,
    getV3AllSelectedAddonRequests,
    getV3PromotionNode,
    getIsIneligible,
  ],
  (
    { planId, discountId, promotion: { programId }, addons, addonBundles },
    addonRequests,
    promotion,
    isIneligible
  ) => {
    const subscription = {
      plan: {
        id: planId,
        programId,
        discountIds: discountId ? [discountId] : [],
      },
      addOns: addons ? addons.map(convertAddonToV3) : [],
      addOnBundles: addonBundles
        ? addonBundles.map(convertAddonBundleToV3)
        : [],
      promotion,
    };

    return isIneligible
      ? subscription
      : {
          ...subscription,
          ...addonRequests,
        };
  }
);

const getV3GiftCodePaymentNode = createSelector(
  [getPayment, getUser],
  ({ code, zip }, { zip: userZip, billingZip }) => ({
    type: V3_PAYMENT_TYPES.GIFT_CODE,
    code,
    zip: zip || userZip || billingZip,
  })
);

const getV3SprintPaymentNode = createSelector(
  getSubscriberId,
  subscriberId => ({
    type: V3_PAYMENT_TYPES.SPRINT,
    token: subscriberId,
  })
);

export const getV3PaymentNode = state => {
  switch (state.payment.paymentType) {
    case paymentMethods.GIFT_CODE:
      return getV3GiftCodePaymentNode(state);
    case paymentMethods.SPRINTV2:
      return getV3SprintPaymentNode(state);
    default:
      return undefined;
  }
};

const getLegalZip = state => state.user.legalZip;

const getHomeZip = state => state.user.zip;

// Normally, we'll capture the prepaid zip code in the legalZip field. However, in the case of
// live prepaid, we'll have captured the zip code in the homeZip field, and we can repurpose it for
// the legalZip.
// A cancelled/classic user may have legal zip as an empty string.
// SPLAT does not accept an empty zip, so we replace it with null.
const getLegalZipForLedgerRequest = createSelector(
  [getLegalZip, getHomeZip, getIsPrepaidForSelectedPlan],
  (legalZip, homeZip, selectedPlanIsPrepaid) => {
    if (selectedPlanIsPrepaid) {
      if (homeZip) return homeZip;
      if (legalZip) return legalZip;
    }
    return null;
  }
);

// TODO WEB-9655: Remove
export const getLedgerRequest = createSelector(
  [
    getV3SubscriptionNode,
    getClient,
    getV3PaymentNode,
    getIsPrepaidForSelectedPlan,
    getLegalZipForLedgerRequest,
  ],
  (subscription, client, payment, selectedPlanIsPrepaid, legalZip) => ({
    subscription,
    client,
    payment,
    legalZip,
  })
);

// Convert v2 addon to v4 ledger request format.
const convertAddonToV4 = ({
  componentId,
  policyId,
  programId,
  discountIds,
}) => ({
  id: componentId,
  policyId,
  programId,
  discountIds,
});

// Covert v2 addonBundle to v4 ledger request format.
const convertAddonBundleToV4 = ({
  bundleId,
  programId,
  discountIds,
  addons,
}) => ({
  id: bundleId,
  programId,
  discountIds,
  components: addons.map(convertAddonToV4),
});

const getAllSelectedV4AddonRequests = createSelector(
  [getSelectedAddons, getSelectedAddonBundles],
  (addons, addonBundles) => ({
    addOns: addons
      ? addons.map(({ requestObject }) => convertAddonToV4(requestObject))
      : [],
    addOnBundles: addonBundles
      ? addonBundles.map(({ requestObject }) =>
          convertAddonBundleToV4(requestObject)
        )
      : [],
  })
);

const getV4SubscriptionNode = createSelector(
  [
    getSelectedPlan,
    getAllSelectedV4AddonRequests,
    getV3PromotionNode,
    getIsIneligible,
  ],
  (
    {
      id,
      componentIds,
      subscription: {
        addons,
        addonBundles,
        discountId,
        policyId,
        promotion: { programId },
      },
    },
    addonRequests,
    promotion,
    isIneligible
  ) => {
    const subscription = {
      plan: {
        id,
        componentIds,
        policyId,
        programId,
        discountIds: discountId ? [discountId] : [],
      },
      addOns: addons ? addons.map(convertAddonToV4) : [],
      addOnBundles: addonBundles
        ? addonBundles.map(convertAddonBundleToV4)
        : [],
      promotion,
    };

    return isIneligible
      ? subscription
      : {
          ...subscription,
          ...addonRequests,
        };
  }
);

export const getV2LedgerRequest = createSelector(
  [
    getV4SubscriptionNode,
    getClient,
    getV3PaymentNode,
    getLegalZipForLedgerRequest,
  ],
  (subscription, client, payment, legalZip) => ({
    subscription,
    client,
    payment,
    legalZip,
  })
);

export const getShouldSkipLedger = createSelector(
  [getIsSpotifyPartnerFlow, getIsBundlePartnerFlow],
  (isSpotifyPartnerFlow, isBundlePartnerFlow) =>
    isSpotifyPartnerFlow || isBundlePartnerFlow
);

/**
 * When true, we redirect the user to Account Management instead of continuing with the Signup flow.
 * Partner signup and Activation flows should never redirect to Account Management.
 */
export const getShouldRedirectToAccount = createSelector(
  [
    getIsLoggedInActiveSubscriber,
    getIsPartnerFlow,
    getIsVerizonDssFlow,
    getIsUnifiedActivationFlow,
  ],
  (
    isLoggedInActiveSubscriber,
    isPartnerFlow,
    isVerizonDssFlow,
    isUnifiedActivationFlow
  ) =>
    isLoggedInActiveSubscriber &&
    !isPartnerFlow &&
    !isVerizonDssFlow &&
    !isUnifiedActivationFlow
);

export const getPaymentNeeded = createSelector(
  [getSelectedPlan, getPayment],
  (selectedPlan, payment) => {
    const paymentNotNeeded = [
      paymentMethods.NONE,
      paymentMethods.GIFT_CODE,
      paymentMethods.RPM,
    ];
    return (
      !(selectedPlan && selectedPlan.paymentType === paymentMethods.NONE) &&
      !(payment && paymentNotNeeded.includes(payment.paymentType))
    );
  }
);

export const getDisplayPaymentFields = createSelector(
  [getPaymentNeeded, getIsPartnerFlow],
  (paymentNeeded, isPartnerFlow) => paymentNeeded && !isPartnerFlow
);

export const getDisplayBillingZipOnAccountPage = createSelector(
  [getPaymentNeeded, getIsPartnerFlow],
  (paymentNeeded, isPartnerFlow) => !paymentNeeded && !isPartnerFlow
);

export const getLedgerTitleText = state =>
  getControlText(state, 'ledger.title.default', 'Your Subscription');

const getLedgerConsent = createSelector([getLedger], ledger =>
  get(ledger, 'consent', [])
);

export const getVermontPrepaidConsentText = createSelector(
  [getIsPrepaidForSelectedPlan, getLedgerConsent],
  (selectedPlanIsPrepaid, ledgerConsent) => {
    if (selectedPlanIsPrepaid) {
      return (
        ledgerConsent.find(consentItem => consentItem.key === 'vermont')
          ?.value || ''
      );
    }

    return '';
  }
);
