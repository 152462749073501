import get from 'lodash/get';

/**
 This flag decides if we have users with cart abandonment cookie to skip plan select step in SUF
 https://app.launchdarkly.com/WebSignupTeam/staging/features/cart-abandonment-plan-select-skip/targeting
 */
export const CART_ABANDONMENT_PLAN_SKIP_FEATURE_FLAG =
  'cart-abandonment-plan-select-skip';
export const DISABLE_ACCOUNT_STATUS_CHECK_FLAG = 'beet';
export const ELIGIBILITY_STATUS_FEATURE_FLAG = 'good-soup';
export const FLASH_SALE = 'rye';
export const TMOBILE_BRANDING = 'tmobile-branding';
export const TWDC_PRIVACY_POLICY = 'twdc-privacy-policy';
export const LIVE_TV_PROMO = 'spice';
export const UPDATED_GENDER_OPTIONS = 'updated-gender-options';
export const ONETRUST_SCRIPT = 'enable-one-trust-script';
export const SIMPLIFIED_LEDGER = 'hulu-ledger-optimization'; // TODO WEB-9655: Remove
export const THREE_STEP_ACCOUNT_CREATION = '3-step-account-creation';
export const PRICE_INCREASE = 'mint';
export const UNIFIED_ACTIVATION = 'unified-activation';
export const HULU_FREE_TRIALS = 'hulu-free-trials';
export const EDNA_PARTNER_FLOW = 'edna-partner-flow';
export const DUO_BUNDLES = 'duo-bundles';
export const LEGAL_TAX_DISCLAIMER = 'legal-tax-disclaimer';
export const HULU_CLIENT_SIGNUP_AGE_LIMIT = 'hulu-client-signup-age-limit';
export const VALIDATE_PAYMENT_ZIP = 'validate-payment-zip';
export const ADS_PREF_LEDGER = 'hulu-sign-up-plan-ads-pref';

const getFeatures = state => get(state, 'featureFlags.featureFlags', null);

export const isFeatureEnabled = (state, feature) =>
  get(getFeatures(state), feature, false);

export const getIsFlashSaleOn = state => isFeatureEnabled(state, FLASH_SALE);

export const getIsTMobileFeatureOn = state =>
  isFeatureEnabled(state, TMOBILE_BRANDING);

export const getIsTWDCPrivacyPolicyFeatureOn = state =>
  isFeatureEnabled(state, TWDC_PRIVACY_POLICY);

export const getIsLivePromoFeatureOn = state =>
  isFeatureEnabled(state, LIVE_TV_PROMO);

/**
 * Determine if the current gender options should be replaced with the inclusive collection
 * shared with DSS. See WEB-5515 for reference.
 */
export const getIsUpdatedGenderOptionOn = state =>
  isFeatureEnabled(state, UPDATED_GENDER_OPTIONS);

/**
 * Determine if the third-party OneTrust SDK should be used to manage
 * the user's personal information preferences.
 */
export const getIsOneTrustScriptEnabled = state =>
  isFeatureEnabled(state, ONETRUST_SCRIPT);

// TODO WEB-9655: Remove
/**
 * Determine if the simplified or existing verbose ledger should be shown
 */
export const getIsSimplifiedLedgerEnabled = state =>
  isFeatureEnabled(state, SIMPLIFIED_LEDGER);

// TODO WEB-10069: Post-launch | Remove 3-Step Account Creation Feature Flag
/**
 * Determine if the 3 step account creation flow should be displayed on web
 */
export const getIs3StepAccountCreationEnabled = state =>
  isFeatureEnabled(state, THREE_STEP_ACCOUNT_CREATION);

/**
 * Determine if we should show US Price Increase 2023 Disclaimers
 */
export const getIsPriceIncreaseEnabled = state =>
  isFeatureEnabled(state, PRICE_INCREASE);

/**
 * Determine if we should show Unified Activation
 */
export const getIsUnifiedActivationEnabled = state =>
  isFeatureEnabled(state, UNIFIED_ACTIVATION);

/**
 * Determine if we should show Hulu Free Trials
 */
export const getIsHuluFreeTrialsEnabled = state =>
  isFeatureEnabled(state, HULU_FREE_TRIALS);

/**
 * Determine if partner flow should be enabled within Edna
 */
export const getIsEdnaPartnerFlowEnabled = state =>
  isFeatureEnabled(state, EDNA_PARTNER_FLOW);

/**
 * Determine if Duo Bundles on Hulu are enabled
 */
export const getIsDuoBundlesFeatureEnabled = state =>
  isFeatureEnabled(state, DUO_BUNDLES);

/**
 * Determine if Legal Tax Disclaimer (2024) on Hulu is enabled
 */
export const getIsLegalTaxDisclaimerFeatureEnabled = state =>
  isFeatureEnabled(state, LEGAL_TAX_DISCLAIMER);

/**
 * Determine if Hulu Client Signup Age Limit is enabled
 */
export const getIsHuluClientSignupAgeLimitFeatureEnabled = state =>
  isFeatureEnabled(state, HULU_CLIENT_SIGNUP_AGE_LIMIT);

/**
 * Determine if Validate Billing Zip is enabled.
 */
export const getIsValidatePaymentZipFeatureEnabled = state =>
  isFeatureEnabled(state, VALIDATE_PAYMENT_ZIP);

/**
 * Determine if Sign Up Flow - Ads Pref is enabled.
 */
export const getIsAdsPrefFeatureEnabled = state =>
  isFeatureEnabled(state, ADS_PREF_LEDGER);
