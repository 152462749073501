import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Form } from 'react-redux-form';
import { PlanPropTypes } from '../components/Plan';
import PlanGroup from '../components/PlanGroup';
import StudentDiscountLink from '../components/StudentDiscountLink';
import PlanSelectMessage from '../components/PlanSelectMessage';
import ProductBanner from '../components/ProductBanner';
import {
  BASIC_PLANS_TAB,
  BUNDLE_PLANS,
  BUNDLE_PLANS_TAB,
} from '../constants/plans';
import LiveTvLink from '../components/LiveTvLink';

require('../styles/form.scss');
require('../styles/plan.scss');

class PlanSelectPage extends Component {
  componentDidMount() {
    this.props.onLoaded();
  }

  render() {
    const {
      plans,
      shouldSkip,
      planMessage,
      defaultPlan,
      disclaimer,
      isLiveOnly,
      planTabIndex,
      showBundleTab,
      bannerOffer,
      isPriceIncreaseEnabled,
      showMaxWithBanner,
      showMaxWithoutBanner,
    } = this.props;

    if (shouldSkip || plans.length === 0) return null;

    const showStudentDiscount = showBundleTab
      ? planTabIndex === BASIC_PLANS_TAB
      : true;

    const showLiveTvLink = showBundleTab
      ? planTabIndex === BUNDLE_PLANS_TAB
      : false;

    const showBundleTermsApply =
      BUNDLE_PLANS.includes(bannerOffer?.plan?.identifier) ||
      bannerOffer?.id === '18999';

    return (
      <Form model="user" className="form plan-select" method="POST">
        <PlanSelectMessage
          header={planMessage.header}
          subheader={planMessage.subheader}
        />
        <PlanGroup
          plans={plans}
          defaultPlan={defaultPlan}
          onClick={(plan, isLiveTvLinkSelected) =>
            this.props.onPlanSelected(plan, isLiveTvLinkSelected)
          }
          isLiveOnly={isLiveOnly}
          planTabIndex={planTabIndex}
          onTabSelect={tabIndex => this.props.onTabSelect(tabIndex)}
          showBundleTab={showBundleTab}
        />
        {disclaimer && (
          <ReactMarkdown
            source={disclaimer}
            className="disclaimer"
            linkTarget="_blank"
            escapeHtml={false}
          />
        )}
        {/* TODO WEB-26131 : cleanup after Max Bundle on Plan Select experiment */}
        {Boolean(bannerOffer) && !showMaxWithoutBanner && (
          <ProductBanner
            isPriceIncreaseEnabled={isPriceIncreaseEnabled}
            offer={bannerOffer.copy}
            onClick={
              bannerOffer.plan
                ? () => this.props.onPlanSelected(bannerOffer.plan)
                : () => this.props.onBannerPlanSelected(bannerOffer.id)
            }
            openModal={() => this.props.onBannerTermsClicked(bannerOffer.id)}
            showBundleTerms={showBundleTermsApply}
          />
        )}
        {showStudentDiscount && <StudentDiscountLink linkClass="page-bottom" />}
        {/* TODO WEB-26131 : cleanup after Max Bundle on Plan Select experiment */}
        {showLiveTvLink && !(showMaxWithoutBanner || showMaxWithBanner) && (
          <LiveTvLink
            linkClass="page-bottom"
            handleLinkClick={this.props.onPlanSelected}
          />
        )}
      </Form>
    );
  }
}

PlanSelectPage.propTypes = {
  plans: PropTypes.arrayOf(PlanPropTypes).isRequired,
  defaultPlan: PlanPropTypes,
  onPlanSelected: PropTypes.func.isRequired,
  onLoaded: PropTypes.func.isRequired,
  shouldSkip: PropTypes.bool.isRequired,
  disclaimer: PropTypes.string,
  planMessage: PropTypes.shape({
    header: PropTypes.string,
    subheader: PropTypes.string,
    buttonText: PropTypes.string,
  }).isRequired,
  isLiveOnly: PropTypes.bool.isRequired,
  planTabIndex: PropTypes.string,
  onTabSelect: PropTypes.func,
  showBundleTab: PropTypes.bool.isRequired,
  bannerOffer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    copy: PropTypes.shape({
      alt_text: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      more_details: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
      tagline: PropTypes.string.isRequired,
    }),
    plan: PropTypes.object,
    planTabIndex: PropTypes.string,
  }),
  onBannerTermsClicked: PropTypes.func.isRequired,
  onBannerPlanSelected: PropTypes.func.isRequired,
  isPriceIncreaseEnabled: PropTypes.bool,
  showMaxWithoutBanner: PropTypes.bool,
  showMaxWithBanner: PropTypes.bool,
};

export default PlanSelectPage;
