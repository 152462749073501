import { connect } from 'react-redux';
import LedgerAdPreference from '../experiments/LedgerAdPreference';
import {
  switchLedgerAndAlternateLedger,
  switchSelectedPlanAndAltSelectedPlan,
} from '../actions/alternateLedgerActions';

// TODO WEB-24281: Cleanup after Hulu Ads Pref Experiment

// For WEB-24169 Ads Pref on Billing Experiment
// Pass dispatch function into LedgerAdsPreference component
// so that we can switch between the default and alternate Ledger
export default connect(null, dispatch => ({
  onSwitchLedgers: () => {
    dispatch(switchLedgerAndAlternateLedger());
    dispatch(switchSelectedPlanAndAltSelectedPlan());
  },
}))(LedgerAdPreference);
