import { createSelector } from 'reselect';
import get from 'lodash/get';

import { getIsLedgerLoading, getShouldSkipLedger } from './ledger';
import { getIsAltLedgerLoading } from './altLedger';
import { getSiteConfig } from './siteconfig';
import { getPaymentType } from './subscription';

const getPayment = state => state.payment;
const getIsSuperbundleEligibilityLoading = state =>
  state.superbundleEligibility.loading;
const getSubscriptionError = state =>
  get(state, 'subscription.createSubscriber.error');
export const getIsSkipBillingFlow = state => getShouldSkipLedger(state);

export const getIsBillingPageLoading = state =>
  getIsLedgerLoading(state) ||
  getIsAltLedgerLoading(state) || // TODO WEB-24281: Cleanup after Hulu Ads Pref Experiment
  getIsSuperbundleEligibilityLoading(state) ||
  (getIsSkipBillingFlow(state) && !getSubscriptionError(state));

export const getBillingCtaButtonText = createSelector(
  [getSiteConfig, getPaymentType],
  (siteConfig, paymentType) => {
    const ctaConfig = get(siteConfig, ['billing_page', 'cta_button'], {});
    return get(ctaConfig, paymentType, get(ctaConfig, 'default', 'SUBMIT'));
  }
);

export const getPaypalClientTokenRequest = createSelector(
  [getPayment],
  ({ paypalRedirect }) => ({
    successUrl: get(paypalRedirect, 'successRedirectUrl', null),
    cancelUrl: get(paypalRedirect, 'cancelRedirectUrl', null),
  })
);

export const getBillingTabIndex = state =>
  get(state, 'payment.billingTabIndex', 0);
