import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppProviders, UnifiedCreateAccount } from '@hulu/web-login-ui';
import { metricsManager } from '../metrics/MetricsManager';

import ErrorScreen from './ErrorScreen';
import { ACCOUNT_INFO } from '../constants/routes';
import { CreateAccountAppRouter } from '../utils/buildAppRouter';
import { getUnifiedFooterConfig } from '../utils/getUnifiedFooterConfig';
import { getShouldShowEdnaCreateAccount } from '../selectors/accountPage';
import {
  getIsHuluClientSignupAgeLimitFeatureEnabled,
  getIsOneTrustScriptEnabled,
} from '../selectors/featureFlag';
import {
  getClient,
  getIsBundlePartnerFlow,
  getPartnerFlow,
} from '../selectors/flow';
import { getEmail, getSelectedPlan } from '../selectors/user';
import { postCreateAccount } from '../actions/accountActions';
import { routeReplace } from '../actions/routingActions';
import * as routes from '../constants/routes';
import { setUserData } from '../actions/userActions';
import { isStudentPlan } from '../selectors/plan';
import { getIdentityEnvironment } from '../utils/environmentUtils';

const EdnaCreateAccountContainer = ({
  isLiveTVSelected,
  isDisplayBillingZip,
  isPrepaidForSelectedPlan,
  isDisplayLegalZip,
}) => {
  const history = useHistory();
  const shouldShowEdnaCreateAccount = useSelector(
    getShouldShowEdnaCreateAccount
  );

  const dispatch = useDispatch();

  const client = useSelector(getClient);
  // This indicates whether the user is coming from the partner flow.
  // If so, it should be set to the corresponding value.
  const partnerFlow = useSelector(getPartnerFlow);
  // The selected plan
  const selectedPlan = useSelector(getSelectedPlan);
  // Email stored within user node
  const userEmail = useSelector(getEmail);

  // Indicate if the flag for the OneTrust script is enabled
  const isOneTrustScriptEnabled = useSelector(getIsOneTrustScriptEnabled);

  // Indicate if the flag for Hulu Client Singup Age limit is enabled
  const isHuluClientSignupAgeLimitFeatureEnabled = useSelector(
    getIsHuluClientSignupAgeLimitFeatureEnabled
  );

  // Check if the selected plan belongs to the student offer
  const studentPlan = isStudentPlan(selectedPlan);

  const isActivationFlow = useSelector(getIsBundlePartnerFlow);

  // The email field is not editable when the user is in the activation flow
  const isEditable = !isActivationFlow;

  useEffect(() => {
    // If a plan is not selected, redirect the user back to the plan selection page.
    if (!selectedPlan) {
      dispatch(routeReplace(routes.PLAN_SELECT));
    }
  }, [dispatch, selectedPlan]);

  const onCreateAccountSuccess = useCallback(
    data => dispatch(postCreateAccount(data)),
    [dispatch]
  );

  if (shouldShowEdnaCreateAccount) {
    const router = CreateAccountAppRouter(history).build();

    // Obtain the environment variable and pass it to the `UnifiedCreateAccount` component.
    // This approach is inspired by the integration with the `WebLogin`.
    // For reference:
    // https://github.prod.hulu.com/SUMO/sufo-redux/blob/develop/src/app/pages/LoginPage.js#L35-L41
    const env = getIdentityEnvironment();

    // The type of the zip code field to be displayed on the create account form.
    // If the value is undefined, the field will not be shown.
    const getZipType = () => {
      if (isLiveTVSelected) {
        return 'zip';
      }

      if (isDisplayBillingZip) {
        return 'billingZip';
      }

      if (isPrepaidForSelectedPlan || isDisplayLegalZip) {
        return 'legalZip';
      }

      return undefined;
    };

    const handleUpdateUserEmail = useCallback(
      email => dispatch(setUserData({ email })),
      [dispatch]
    );

    const config = {
      baseRoute: ACCOUNT_INFO,
      client,
      env,
      isEditable,
      isStudentPlan: studentPlan,
      onCreateAccountSuccess,
      partnerFlow,
      router,
      selectedPlan,
      enableIncreasedSignupAgeLimit: isHuluClientSignupAgeLimitFeatureEnabled,
      trackEvent: metricsManager.trackEvent,
      updateUserEmail: handleUpdateUserEmail,
      userEmail,
      zipType: getZipType(),
    };

    return (
      <AppProviders>
        <UnifiedCreateAccount
          config={config}
          footerConfig={getUnifiedFooterConfig(isOneTrustScriptEnabled)}
        />
      </AppProviders>
    );
  }

  return <ErrorScreen />;
};

EdnaCreateAccountContainer.propTypes = {
  isDisplayBillingZip: PropTypes.bool,
  isDisplayLegalZip: PropTypes.bool,
  isLiveTVSelected: PropTypes.bool,
  isPrepaidForSelectedPlan: PropTypes.bool,
};

export default EdnaCreateAccountContainer;
