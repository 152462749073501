import { GET_ALT_LEDGER, SWITCH_ALT_LEDGER } from '../constants/actionTypes';

export const initialState = {
  altLedger: null,
  error: null,
  loading: false,
};

// TODO WEB-24281: Cleanup after Hulu Ads Pref Experiment

// For WEB-24169 Ads Pref on Billing Experiment
// Alternate ledger should be stored in state so that it is available
// to display on the billing screen.
function altLedger(state = initialState, action) {
  switch (action.type) {
    case GET_ALT_LEDGER.START:
      return {
        ...state,
        loading: true,
      };
    case GET_ALT_LEDGER.SUCCESS:
      return {
        ...state,
        altLedger: action.payload,
        loading: false,
      };
    case GET_ALT_LEDGER.FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_ALT_LEDGER.RESET:
      return {
        ...initialState,
      };
    case SWITCH_ALT_LEDGER:
      return {
        ...state,
        altLedger: action.newAltLedger,
        loading: false,
      };
    default:
      return state;
  }
}

export default altLedger;
