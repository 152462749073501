import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';
import get from 'lodash/get';

import { parseDate } from '../utils/dateUtils';
import * as types from '../constants/actionTypes';

export const initialUserState = {
  email: '',
  firstName: '',
  selectedPlan: null,
  userCreateLoading: false,
  userCreationPasswordCheckError: '',
  accountSubmitted: false,
  legalZip: '',
  userCreationRecaptchaToken: '',
};

const compareComponentId = (selectedAddon, toggledAddon) =>
  selectedAddon.componentId === toggledAddon.componentId;
const compareBundleId = (selectedAddonBundle, toggledAddonBundle) =>
  selectedAddonBundle.bundleId === toggledAddonBundle.bundleId;

function user(state = initialUserState, action) {
  switch (action.type) {
    case types.GET_CONFIG.SUCCESS: {
      const configUser = { ...action.payload.user };
      if (configUser.birthday) {
        configUser.birthday = parseDate(configUser.birthday);
      }

      // Keeps existing zip if config returns a null.
      configUser.zip = configUser.zip || state.zip;

      if (state.selectedPlan !== null) {
        const currentLegalTerms = state.selectedPlan.legalTerms;
        const updatedSelectedPlan = action.payload.plans.find(
          plan => plan.id === state.selectedPlan.id
        );
        const updatedLegalTerms = get(
          updatedSelectedPlan,
          'legalTerms',
          currentLegalTerms
        );

        // If there are legal term changes, update the selectedPlan's legalTerms but keep the programIds.
        // The programId changes are handled somewhere else and will require the user to reselect a plan.
        if (!isEqual(updatedLegalTerms, currentLegalTerms)) {
          configUser.selectedPlan = {
            ...state.selectedPlan,
            legalTerms: updatedLegalTerms,
          };
        }
      }
      return {
        ...state,
        ...configUser,
      };
    }
    case types.GET_SPOTIFY_ELIGIBILITY.SUCCESS: {
      const spotifyUser = { ...action.payload.user };
      if (spotifyUser.birthday) {
        spotifyUser.birthday = parseDate(spotifyUser.birthday);
      }
      return {
        ...state,
        ...spotifyUser,
      };
    }
    case types.PROCESS_PAYMENT_ERRORS:
      if (action.error) {
        const newState = {
          ...state,
          ...action.user,
        };

        if (action.plan) {
          newState.selectedPlan = action.plan;
        }

        if (action.addons) {
          newState.selectedPlan.subscription.addons = action.addons;
        }

        if (action.addonBundles) {
          newState.selectedPlan.subscription.addonBundles = action.addonBundles;
        }

        return newState;
      }
      return state;
    case types.SELECT_PLAN:
      if (action.plan) {
        return {
          ...state,
          selectedPlan: action.plan,
        };
      }
      return state;
    case types.SELECT_ALT_PLAN:
      if (action.plan) {
        return {
          ...state,
          altSelectedPlan: action.plan,
        };
      }
      return state;
    case types.TOGGLE_ADDON:
      if (action.addon) {
        const selectedAddons = state.selectedPlan.subscription.addons;

        // add/remove addon request object by comparing the componentId
        // If the addon already exists, remove it.
        // If the addon does not exist, add it to the selectedPlan.
        const newAddons = xorWith(
          selectedAddons,
          [action.addon],
          compareComponentId
        );

        state.selectedPlan.subscription.addons = newAddons;
      }
      return state;
    case types.TOGGLE_ADDON_BUNDLE:
      if (action.addonBundle) {
        const selectedAddonBundles =
          state.selectedPlan.subscription.addonBundles;

        // add/remove addon bundle request object by comparing the bundleId
        // If the bundle already exists, remove it.
        // If the bundle does not exist, add it to the selectedPlan.
        const newAddonBundles = xorWith(
          selectedAddonBundles,
          [action.addonBundle],
          compareBundleId
        );

        state.selectedPlan.subscription.addonBundles = newAddonBundles;
      }
      return state;
    case types.GET_BUNDLE_PARTNER_PAYLOAD.SUCCESS:
      return {
        ...state,
        email: get(action, 'payload.email', state.email),
      };
    case types.ACCOUNT_SUBMIT.START:
      return {
        ...state,
        userCreateLoading: true,
      };
    case types.ACCOUNT_SUBMIT.SUCCESS:
      return {
        ...state,
        accountSubmitted: true,
        userCreateLoading: false,
      };
    case types.ACCOUNT_SUBMIT.GENERAL_FAILURE:
      return {
        ...initialUserState,
        error: action.payload,
      };
    case types.ACCOUNT_SUBMIT.PASSWORD_CHECK_FAILURE:
      return {
        ...state,
        userCreateLoading: false,
        userCreationPasswordCheckError: action.payload,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...initialUserState,
        loggedIn: false,
      };
    case types.USER_CREATION_RECAPTCHA:
      return {
        ...state,
        userCreationRecaptchaToken: action.token,
      };

    case types.SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      break;
  }
  return state;
}

export default user;
