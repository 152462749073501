import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Errors, Field, actions } from 'react-redux-form';

import InfoTooltip from './InfoTooltip';
import { getFormFieldValidity } from '../selectors/form';

class TextField extends Component {
  constructor() {
    super();
    this.state = {
      prevValue: '',
    };

    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  shouldComponentUpdate(nextProp, nextState) {
    // This prevents fields with errorWrapper to update when not needed
    // avoiding error messages blinking
    return (
      !this.props.errorWrapper || this.state.prevValue !== nextState.prevValue
    );
  }

  handleOnBlur(e) {
    const { prevValue } = this.state;
    const { dispatch, onBlur } = this.props;
    const { name, value } = e.target;

    if (value) {
      dispatch(actions.change(name, value.trim()));
    }

    if (onBlur) {
      onBlur(e);
    }

    if (value && value !== prevValue) {
      this.setState({ prevValue: value });
    }
  }

  render() {
    const {
      model,
      className,
      description,
      disabled,
      validators,
      updateOn,
      type,
      pattern,
      inputMode,
      placeHolder,
      maxLength,
      onChange,
      onKeyDown,
      onKeyUp,
      onPaste,
      children,
      errorWrapper: ErrorWrapper,
      messages,
      tooltip,
      tooltipClassName,
      displayName,
      valid,
      asyncValidators,
      asyncValidateOn,
    } = this.props;

    return (
      <div className={`fieldset ${className}`}>
        <div className="label">
          <label htmlFor={model} className="label__input">
            {displayName}
          </label>
          {tooltip && (
            <InfoTooltip
              tooltip={tooltip}
              className={tooltipClassName}
              id={`${tooltipClassName}-tooltip`}
            />
          )}
        </div>
        {description && <div className="field__description">{description}</div>}
        <div className={`field cu-${model}`}>
          <Field
            model={`.${model}`}
            validators={validators}
            asyncValidators={asyncValidators}
            asyncValidateOn={asyncValidateOn}
            updateOn={updateOn}
            className="input__wrapper"
          >
            <Errors className="has-error" model={`.${model}`} show="touched" />
            <input
              type={type}
              id={model}
              pattern={pattern}
              autoCorrect="off"
              className="input__text"
              aria-describedby={`${model}-error`}
              aria-invalid={!valid}
              inputMode={inputMode}
              placeholder={placeHolder}
              maxLength={maxLength}
              onBlur={this.handleOnBlur}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onPaste={onPaste}
              disabled={!!disabled}
              data-testid={model}
            />
            {children}
          </Field>
          <Errors
            className="errors"
            model={`.${model}`}
            show="touched"
            messages={messages}
            wrapper={
              ErrorWrapper ? props => <ErrorWrapper {...props} /> : undefined
            }
            data-testid={`${model}.errors`}
            component={
              model === 'email'
                ? undefined
                : props => <span id={`${model}-error`}>{props.children}</span>
            }
          />
        </div>
      </div>
    );
  }
}

TextField.defaultProps = {
  updateOn: 'change',
  type: 'text',
  className: '',
  valid: false,
};

TextField.propTypes = {
  messages: PropTypes.object.isRequired,
  validators: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  model: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string,
  placeHolder: PropTypes.string,
  maxLength: PropTypes.string,
  children: PropTypes.node,
  updateOn: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
  tooltip: PropTypes.element,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  errorWrapper: PropTypes.object,
  inputMode: PropTypes.string,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onPaste: PropTypes.func,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  asyncValidators: PropTypes.object,
  asyncValidateOn: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  valid: getFormFieldValidity(state, `${props.form}.${props.model}`),
});

export default connect(mapStateToProps)(TextField);
