import React from 'react';

import { getRandomCreditCard, getRandomUser } from '../helpers/dataGenerator';
import { populateField } from '../helpers/testingUtils';

export const FastPassBilling = () => {
  const getRandomData = () => {
    return {
      creditcard: getRandomCreditCard(),
      user: getRandomUser(),
    };
  };

  const handleOnClick = () => {
    const { creditcard, user } = getRandomData();
    populateField('ownerFullName', user.name);
    populateField('creditCard', creditcard.number);
    populateField('expiry', creditcard.expiration);
    populateField('cvc', creditcard.cvc);
    populateField('zip', creditcard.zipCode);

    try {
      document.querySelector("[type='submit']").focus();
    } catch (e) {
      // e
    }
  };

  return (
    <div className="testing-dashboard__billing">
      <button
        className="image-button"
        data-testid="fastpass-billing-button"
        type="button"
        onClick={handleOnClick}
      >
        <img
          alt="Fast Pass Icon"
          src="/static/images/testing-dashboard-icon.svg"
        />
      </button>
    </div>
  );
};
