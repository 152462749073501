import { MetricsTracker } from '@hulu/site-metrics';
import get from 'lodash/get';

import { getNewGuid } from '../utils/browserUtils';
import { noticeError } from '../api/NewRelic';
import * as MetricsEvents from './metricsEvents';
import {
  getFlowName,
  getTrialPeriod,
  getSubscriptionErrorReason,
  getHITPaymentMethod,
  getLedgerInvoiceTotalAmountInCents,
  getSelectedPlanDurationInMonths,
  getCurrPageUri,
} from '../utils/metricsUtils';
import { HEIMDALL_HUB_MAPPINGS } from '../constants/heimdallHubMappings';
import { getAggregateSelectedAddonIdentifiers } from '../selectors/addons';
import { BILLING_TYPES_MAPPING } from '../constants/ledger';
import { getIsDueToday, getLedger } from '../selectors/ledger';
import { getPreselectedPlanProgramId } from '../selectors/plans';

const getEnvironment = () => process.env.NODE_ENV || 'production';
const isDevOrStagingEnvironment = () =>
  process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'smoke';

let siteMetricsInstance;

const utils = {
  appComponentName: 'sufo-redux',
  environmentUtils: {
    getEnvironment,
    isDevOrStagingEnvironment,
    getDogFoodFlavor: getEnvironment,
    PRODUCTION_ENVS: ['production', 'smoke'],
  },
  logger: {
    error: noticeError,
    warn: console.warn,
    info: console.log,
  },
};

export class SiteMetricsTracker {
  constructor() {
    this.startingStage = null;
    this.subscriptionEndExtraProperties = {};
    this.uniquePageId = getNewGuid();
    this.initialize = this.initialize.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.loadMetricsTracker = this.loadMetricsTracker.bind(this);
  }

  initialize(config) {
    this.loadMetricsTracker(config);
    siteMetricsInstance.trackApplicationStart(global.performance);
  }

  /**
   * Track an event by sending it to Vortex (the Site Metrics pipeline).
   *
   * Specs:
   * - https://wiki.hulu.com/display/RAD/Subscription+Instrumentation
   *
   * These events are sent via the `@hulu/site-metrics` library, which
   * are routed to Vortex owned by the DNA #instrumentation team. Events
   * fired by Site Metrics must be in the client metrics config whitelist.
   * See the `event_whitelist` node at:
   * http://site-config.staging.hulu.com/dev?name=client_metrics_config
   *
   * @param {string} eventName The name of the event to track.
   * @param {object} state The redux store's current state.
   * @param {object} options Any extra options provided by the callee.
   */
  trackEvent(eventName, state, options = {}) {
    this.loadMetricsTracker();

    const properties = {
      flow_name: getFlowName(state),
      cmp_id:
        get(state, 'user.selectedPlan.subscription.source.campaignId') || '',
      wholesale_bundle_id: get(
        state,
        'partner.spotifyEligibility.response.bundleId',
        ''
      ),
      wholesale_lp_bundle_id: get(state, 'flow.query.spotify_bundle_id', ''),
      build_number: process.env.commitHash,
      app_component_version: process.env.version,
      curr_page_uri:
        options.curr_page_uri || getCurrPageUri(state, options.pathname),
    };

    switch (eventName) {
      case MetricsEvents.SUBSCRIPTION_START:
        properties.hit_version = '1.2.0';
        properties.selected_program_id = SiteMetricsTracker.stringifyId(
          getPreselectedPlanProgramId(state),
          ''
        );
        break;
      case MetricsEvents.SUBSCRIPTION_STEP_START:
        properties.hit_version = '1.2.0';
        properties.screen_name = options.title;
        if (!this.startingStage) {
          if (state.flow.partnerFlow) {
            // for partner flows, manually specify the starting stage
            this.startingStage = 'SUF - Landing Page';
          } else {
            this.startingStage = options.title;
          }
        }
        break;
      case MetricsEvents.SUBSCRIPTION_PLAN_SELECT:
        properties.hit_version = '1.1.0';
        properties.selected_bundle_id = SiteMetricsTracker.stringifyId(
          get(state, 'user.selectedPlan.id')
        );
        properties.selected_bundle_name = get(
          state,
          'user.selectedPlan.identifier'
        );
        properties.selected_program_id = SiteMetricsTracker.stringifyId(
          get(state, 'user.selectedPlan.subscription.promotion.programId')
        );
        break;
      case MetricsEvents.SUBSCRIPTION_END:
        // Since we're calling subscription_end, we want to pick up the
        // latest version of all of the previous properties we've ever used
        // and throw them into this event.
        // Store all of those previous properties into our current properties object.
        Object.assign(properties, this.subscriptionEndExtraProperties);
        properties.hit_version = '1.3.0';
        properties.starting_stage = this.startingStage;
        properties.outcome = options.outcome || 'new_subscription';
        properties.selected_bundle_id = SiteMetricsTracker.stringifyId(
          get(state, 'user.selectedPlan.id')
        );
        properties.selected_bundle_name = get(
          state,
          'user.selectedPlan.identifier'
        );
        properties.selected_program_id = SiteMetricsTracker.stringifyId(
          get(state, 'user.selectedPlan.subscription.promotion.programId')
        );
        properties.selected_product_id = SiteMetricsTracker.stringifyId(
          options.productId
        );
        properties.payment_method = getHITPaymentMethod(state);
        properties.trial_period = getTrialPeriod(state.user.selectedPlan);
        properties.account_type = 'hulu';
        properties.is_new_account = state.user.status === 'available';
        if (state.config.errorMessages.length !== 0) {
          properties.last_error_reason = state.config.errorMessages.slice(
            -1
          )[0].code;
        }
        properties.selected_addons = getAggregateSelectedAddonIdentifiers(
          state
        );
        properties.billing_type = get(
          BILLING_TYPES_MAPPING,
          get(getLedger(state), 'subscription.plan.billingType', 'none'),
          null
        );
        if (properties.billing_type === BILLING_TYPES_MAPPING.standard) {
          // monthly_total_due needs unit in cents but the ledger invoice has the total amount in dollars
          properties.monthly_total_due = getLedgerInvoiceTotalAmountInCents(
            state
          );
        } else {
          properties.selected_plan_duration = getSelectedPlanDurationInMonths(
            state
          );
          // amount_due_today needs unit in cents but the ledger invoice has the total amount in dollars
          properties.amount_due_today = getIsDueToday(state)
            ? getLedgerInvoiceTotalAmountInCents(state)
            : 0;
        }
        delete properties.screen_name;
        break;
      case MetricsEvents.SUBSCRIPTION_PAYMENT_ERROR:
        properties.hit_version = '1.2.1';
        properties.reason = getSubscriptionErrorReason(state, options.error);
        break;
      case MetricsEvents.PAGE_IMPRESSION:
        properties.hit_version = options.hit_version;
        properties.page_source = 'app';
        properties.heimdall_hub_id =
          HEIMDALL_HUB_MAPPINGS[options.pathname] || null;
        properties.curr_page_type = options.curr_page_type || 'signup';
        properties.collection_count = null;
        properties.is_first_impression = options.is_first_impression;
        properties.duration = options.duration;
        properties.page_instance_id = this.uniquePageId;
        properties.is_coppa = 'false';
        properties.addonId = options.id;
        properties.screen_name = options.title;
        break;
      case MetricsEvents.USER_INTERACTION:
        properties.hit_version = options.hit_version;
        properties.page_source = 'app';
        properties.heimdall_hub_id =
          HEIMDALL_HUB_MAPPINGS[options.pathname] || null;
        properties.curr_page_type = 'signup';
        properties.collection_count = null;
        properties.page_instance_id = this.uniquePageId;
        properties.is_coppa = 'false';
        properties.action_specifier = options.action_specifier;
        properties.element_specifier = options.element_specifier;
        properties.interaction_type = options.interaction_type;
        break;
      case MetricsEvents.USER_LOGIN:
        // https://wiki.hulu.com/display/RAD/User+Login+HIT
        properties.hit_version = '1.0.0';
        properties.auth_method = options.auth_method;
        break;
      case MetricsEvents.LOGIN_START:
        properties.hit_version = options.hit_version;
        properties.auth_method = options.auth_method;
        break;
      default:
        return;
    }

    // Hold on to all of the previous property values.
    // When we call subscription_end, we'll want to pass the latest version of all of these values again.
    // So store all of the properties from this event into an amalgamated object.
    Object.assign(this.subscriptionEndExtraProperties, properties);
    siteMetricsInstance.trackEvent(eventName, properties);
  }

  getEventList() {
    return [
      MetricsEvents.SUBSCRIPTION_STEP_START,
      MetricsEvents.SUBSCRIPTION_PLAN_SELECT,
      MetricsEvents.SUBSCRIPTION_START,
      MetricsEvents.SUBSCRIPTION_END,
      MetricsEvents.SUBSCRIPTION_PAYMENT_ERROR,
      MetricsEvents.PAGE_IMPRESSION,
      MetricsEvents.USER_LOGIN,
      MetricsEvents.USER_INTERACTION,
      MetricsEvents.AGE_ROADBLOCK,
      MetricsEvents.LOGIN_START,
    ];
  }

  loadMetricsTracker(config) {
    if (typeof siteMetricsInstance === 'undefined') {
      const isProd = getEnvironment() === 'production';
      const configName = `client_metrics_config.${
        isProd ? 'production' : 'development'
      }`;
      const siteConfig = config ? config[configName] : null;
      siteMetricsInstance = new MetricsTracker({ ...utils }, siteConfig);
    }
  }

  static stringifyId(id, defaultValue) {
    if (id === null || id === undefined) {
      return defaultValue;
    }

    if (typeof id === 'number') {
      return String(id);
    }

    return id;
  }
}
