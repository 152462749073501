import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';

import browserHistory from './browserHistory';
import { store } from './store';
import App from './containers/App';
import MetricsManagerProvider from './metrics/MetricsManagerProvider';
import { metricsManager } from './metrics/MetricsManager';
import * as newrelic from './api/NewRelic';

newrelic.init();

require('./styles/html.scss');
require('@solo/global-identity-ui/dist/style.css');

class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <MetricsManagerProvider store={store} metricsManager={metricsManager}>
          <Router history={browserHistory}>
            <Route path="/" component={App} />
          </Router>
        </MetricsManagerProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
