import { datadogRum } from '@datadog/browser-rum';
import { fireWebVitals } from '@hulu/web-vitals';
import React, { Component, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { matchPath } from 'react-router-dom';
import ReCAPTCHA from '@hulu/web-google-recaptcha';

import { url, datadog, recaptcha } from '../../../config/env';
import {
  ONETRUST_SDK_SOURCE_URL,
  ONETRUST_SDK_DOMAIN_SCRIPT,
} from '../constants/misc';
import routes from '../routes';
import Loading from '../components/Loading';
import {
  LocationPropTypes,
  PageMessagingPropTypes,
} from '../constants/propTypes';
import ModalContainer from '../containers/ModalContainer';
import PageTopNav from '../containers/PageTopNavContainer';
import SecuritySymbol from '../components/SecuritySymbol';
import { ABOUT_ADS_LINK } from '../constants/footer';
import { setAuthRecaptchaRef } from '../utils/recaptcha';
import { ACCOUNT_CREATION, ACCOUNT_INFO, LOGIN } from '../constants/routes';

require('../styles/page.scss');
require('../styles/partner.scss');
require('../styles/roku.scss');

const RecaptchaProvider = ({ children }) => {
  const authRecaptchaRef = useRef();

  useEffect(() => {
    setAuthRecaptchaRef(authRecaptchaRef);
  });

  return (
    <>
      {children}
      {/* Recaptcha component for IDM /authenticate */}
      <ReCAPTCHA
        ref={authRecaptchaRef}
        size="invisible"
        sitekey={recaptcha.authentication.publicKey}
      />
    </>
  );
};

RecaptchaProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * Create the scripts to initialize the OneTrust SDK that is used to manage
 * the user's personal information preferences.
 *
 * @see OneTrust Developer Docs {@link https://developer.onetrust.com/onetrust}
 */
export function createOneTrustScripts() {
  function handleScriptLoadError() {
    datadogRum.addAction('initialize_onetrust_sdk_error');
  }

  const isProd =
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'smoke';

  const sdkScriptAttributes = {
    src: ONETRUST_SDK_SOURCE_URL,
    'data-document-language': 'true',
    type: 'text/javascript',
    charSet: 'UTF-8',
    'data-domain-script': isProd
      ? ONETRUST_SDK_DOMAIN_SCRIPT.prod
      : ONETRUST_SDK_DOMAIN_SCRIPT.nonprod,
  };
  const callbackScriptCode = 'function OptanonWrapper() {}';

  const oneTrustSdkScript = document.createElement('script');
  Object.entries(sdkScriptAttributes).forEach(([key, value]) => {
    oneTrustSdkScript.setAttribute(key, value);
  });
  oneTrustSdkScript.addEventListener('error', handleScriptLoadError);

  const oneTrustCallbackScript = document.createElement('script');
  oneTrustCallbackScript.type = 'text/javascript';
  oneTrustCallbackScript.appendChild(
    document.createTextNode(callbackScriptCode)
  );
  oneTrustCallbackScript.addEventListener('error', handleScriptLoadError);

  return [oneTrustSdkScript, oneTrustCallbackScript];
}

class Page extends Component {
  componentDidMount() {
    // Initializes DataDog RUM Browser Monitoring and
    // collects web-vitals metrics.
    // Note that the sessions sampling rate is set to 10% for Datadog RUM in
    // all apps across Web due to cost concerns. We may need to
    // change it to 100% to locally test populating data to Datadog.
    fireWebVitals({
      applicationId: datadog.applicationId,
      clientToken: datadog.clientToken,
      env: process.env.NODE_ENV,
      service: 'sufo-redux',
      sampleRate: 10,
      version: process.env.version,
    });

    this.handleMetaRobotsTags();

    this.props.onLoaded();
  }

  componentDidUpdate() {
    this.handleMetaRobotsTags();
  }

  handleMetaRobotsTags() {
    // Routes that should not be indexed by a search engine
    const noIndexRoutes = ['/account/create-account'];

    const currentPathname = window.location.pathname;
    const existingMetaIndexTags = Array.from(document.head.children).filter(
      tag =>
        tag.tagName === 'META' &&
        (tag.name === 'robots' || tag.name === 'googlebot') &&
        tag.content === 'index,follow'
    );

    const existingMetaNoIndexTags = Array.from(document.head.children).filter(
      tag =>
        tag.tagName === 'META' &&
        tag.name === 'robots' &&
        tag.content === 'noindex'
    );

    // All pages should either have meta robots index tags or noindex tags, but not both.
    console.assert(
      (existingMetaIndexTags.length === 0) !==
        (existingMetaNoIndexTags.length === 0),
      'Found both meta robots index and noindex tags in HTML.'
    );

    if (
      noIndexRoutes.includes(currentPathname) &&
      existingMetaNoIndexTags.length === 0
    ) {
      // Remove all meta robots index tags and append meta robots noindex tags to the HTML.
      existingMetaIndexTags.forEach(tag => tag.remove());

      const metaRobotsNoindexTag = document.createElement('meta');
      metaRobotsNoindexTag.name = 'robots';
      metaRobotsNoindexTag.content = 'noindex';

      document.head.appendChild(metaRobotsNoindexTag);
    } else if (
      !noIndexRoutes.includes(currentPathname) &&
      existingMetaNoIndexTags.length > 0
    ) {
      // Remove all meta robots noindex tags and append meta robots index tags to the HTML.
      existingMetaNoIndexTags.forEach(tag => tag.remove());

      const metaRobotsIndexTag = document.createElement('meta');
      metaRobotsIndexTag.name = 'robots';
      metaRobotsIndexTag.content = 'index,follow';

      const metaGooglebotIndexTag = document.createElement('meta');
      metaGooglebotIndexTag.name = 'googlebot';
      metaGooglebotIndexTag.content = 'index,follow';

      document.head.appendChild(metaRobotsIndexTag);
      document.head.appendChild(metaGooglebotIndexTag);
    }
  }

  showOneTrustOptOutModal(event) {
    event.preventDefault();

    const activeGroups = window?.OnetrustActiveGroups || '';
    // Split by comma and filter out empty values
    const currentGroupCount = activeGroups.split(',').filter(group => group)
      .length;

    if (window && window.OneTrust) {
      window.OneTrust.OnConsentChanged(consentEvent => {
        const newGroupCount = consentEvent?.detail?.length;
        // After the user saves the personal information preferences,
        // trigger a page reload if the active group count changed.
        if (currentGroupCount !== newGroupCount && window.location) {
          window.location.reload();
        }
      });
      // Show the "Opt Out of Sale/Sharing" modal pop-up provided by OneTrust SDK,
      // rather than opening the link on the footer.
      window.OneTrust.ToggleInfoDisplay();
    }
  }

  render() {
    const {
      dispatch,
      pageMessaging,
      isEdnaAccountCreationFeatureEnabled,
      isRokuPacFlow,
      location,
      footerClass,
      pageTitleClass,
      partnerClass,
      vipClass,
      planSelectClass,
      pageName,
      bootstrapLoading,
      modalIsOpen,
      shouldRedirectToAccount,
      shouldEnableOneTrustScript,
    } = this.props;

    if (bootstrapLoading || shouldRedirectToAccount) {
      return (
        <RecaptchaProvider>
          <Loading />
        </RecaptchaProvider>
      );
    }

    if (isRokuPacFlow) {
      return (
        <RecaptchaProvider>
          <div
            className={`roku-web page ${pageName}`}
            aria-hidden={modalIsOpen}
          >
            <PageTopNav />
            <div className="page__body--wrapper">
              <div className="page__body">{routes()}</div>
            </div>
            <div className={footerClass}>
              <div className="footer__content">
                <div className="logo" />
                <span>Partner login portal</span>
              </div>
            </div>
          </div>
          <ModalContainer />
        </RecaptchaProvider>
      );
    }

    // Append the OneTrust scripts at the top of the HTML head node
    // The scripts only need to be loaded once in each page.
    if (!this.isOneTrustLoaded && shouldEnableOneTrustScript) {
      const oneTrustScripts = createOneTrustScripts();
      document.head.prepend(...oneTrustScripts);
      this.isOneTrustLoaded = true;
    }

    // Default page header of Hulu if it would be blank or undefined otherwise.
    document.title = pageMessaging.header || 'Hulu';

    const doNotSellMyInfoFooterLink = shouldEnableOneTrustScript
      ? url.doNotSellMyInfoTWDC
      : `${url.site}/do-not-sell-my-info`;

    const doNotSellMyInfoFooterText = shouldEnableOneTrustScript
      ? 'Do Not Sell or Share My Personal Information'
      : 'Do Not Sell My Personal Information';

    const privacyRightsFooterText = shouldEnableOneTrustScript
      ? 'Your US State Privacy Rights'
      : 'Your California Privacy Rights';

    const onDoNotSellMyInfoFooterClicked = shouldEnableOneTrustScript
      ? this.showOneTrustOptOutModal
      : undefined;

    // This is the pathname pattern for Account Creation Path:
    // - /account The root path for account creation.
    // - /account/* Matches any subpath under /account
    const ednaAccountMatch = () =>
      matchPath(location.pathname, {
        path: [ACCOUNT_INFO, ACCOUNT_CREATION],
        exact: true,
      });

    if (isEdnaAccountCreationFeatureEnabled && ednaAccountMatch()) {
      return (
        <RecaptchaProvider>
          <div aria-hidden={modalIsOpen}>{routes()}</div>
          <ModalContainer />
        </RecaptchaProvider>
      );
    }

    // This is the pathname pattern for the Login pages:
    // - /login: The root path for login.
    // - /login/*: Matches any sub-paths / dynamic routes under the root path.
    // Ex. /login/enter-email.
    const ednaLoginMatch = () =>
      matchPath(location.pathname, {
        path: [`${LOGIN}*`],
        exact: true,
      });

    // Hide the header and footer when the Edna Login feature component from the web-login-ui package
    // takes over the full screen.
    if (ednaLoginMatch()) {
      return (
        <>
          <div aria-hidden={modalIsOpen}>{routes()}</div>
          <ModalContainer />
        </>
      );
    }

    return (
      <RecaptchaProvider>
        <div
          className={`page ${pageName} ${planSelectClass} ${partnerClass} ${vipClass}`}
          aria-hidden={modalIsOpen}
        >
          <PageTopNav />
          <main className="page__body--wrapper">
            {(pageMessaging.header ||
              pageMessaging.subheader ||
              pageMessaging.showSecuritySymbol) && (
              <div className={`page__title ${pageTitleClass}`}>
                {Array.isArray(pageMessaging.header) ? (
                  pageMessaging.header.map(h => <h1 key={h}>{h}</h1>)
                ) : (
                  <h1>{pageMessaging.header}</h1>
                )}
                <div className="page__subtitle">
                  <ReactMarkdown
                    source={pageMessaging.subheader}
                    linkTarget="_blank"
                    renderers={
                      pageMessaging.getSubheaderRenderers &&
                      pageMessaging.getSubheaderRenderers(dispatch)
                    }
                  />
                </div>
                {pageMessaging.showSecuritySymbol && <SecuritySymbol />}
              </div>
            )}
            <div className="page__body">{routes()}</div>
          </main>
          <div className="page__bottom">{pageMessaging.below}</div>
          <footer className={footerClass}>
            <ul>
              <li>
                <a
                  href={ABOUT_ADS_LINK}
                  key="ads"
                  className="page__about-ads"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    alt="Evidon AdChoices"
                    src="//c.evidon.com/pub/icon1.png"
                    role="presentation"
                  />
                  About Ads
                </a>
              </li>
              <li>
                <a
                  href={`${url.site}/subscriber_agreement`}
                  key="subscriber"
                  target="_blank"
                  rel="noopener"
                >
                  Subscriber Agreement
                </a>
              </li>
              <li>
                <a
                  href={`${url.site}/privacy`}
                  key="privacy"
                  target="_blank"
                  rel="noopener"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={doNotSellMyInfoFooterLink}
                  key="do-not-sell-my-info"
                  target="_blank"
                  rel="noopener"
                  onClick={onDoNotSellMyInfoFooterClicked}
                >
                  {doNotSellMyInfoFooterText}
                </a>
              </li>
              <li>
                <a
                  href={`${url.site}/ca-privacy-rights`}
                  key="ca-privacy-rights"
                  target="_blank"
                  rel="noopener"
                >
                  {privacyRightsFooterText}
                </a>
              </li>
              <li>&copy; {new Date().getFullYear()} Hulu, LLC</li>
            </ul>
          </footer>
        </div>
        <ModalContainer />
      </RecaptchaProvider>
    );
  }
}

Page.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onLoaded: PropTypes.func.isRequired,
  bootstrapLoading: PropTypes.bool.isRequired,
  isEdnaAccountCreationFeatureEnabled: PropTypes.bool.isRequired,
  isRokuPacFlow: PropTypes.bool.isRequired,
  location: LocationPropTypes,
  footerClass: PropTypes.string.isRequired,
  pageTitleClass: PropTypes.string,
  partnerClass: PropTypes.string,
  vipClass: PropTypes.string,
  planSelectClass: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool,
  pageMessaging: PageMessagingPropTypes,
  shouldEnableOneTrustScript: PropTypes.bool,
  shouldRedirectToAccount: PropTypes.bool,
};

export default Page;
