export const PROCESS_PAYMENT_ERRORS = 'PROCESS_PAYMENT_ERRORS';
export const CHANGE_MODAL = 'CHANGE_MODAL';
export const UPDATE_PAYMENT_TOKEN = 'UPDATE_PAYMENT_TOKEN';
export const SAVE_INITIAL_LOCATION = 'SAVE_INITIAL_LOCATION';
export const CLEAR_LOCATION_QUERY = 'CLEAR_LOCATION_QUERY';
export const REPLACE_LOCATION_QUERY = 'REPLACE_LOCATION_QUERY';
export const PAYMENT_TAB_SELECTED = 'PAYMENT_TAB_SELECTED';
export const SHOULD_SKIP_PLAN_SELECT = 'SHOULD_SKIP_PLAN_SELECT';
export const SHOULD_SHOW_PLAN_SELECT_MESSAGE =
  'SHOULD_SHOW_PLAN_SELECT_MESSAGE';
export const CLEAR_PREFILL_BILLING_INFO = 'CLEAR_PREFILL_BILLING_INFO';
export const SELECT_PLAN = 'SELECT_PLAN';
export const SELECT_ALT_PLAN = 'SELECT_ALT_PLAN';
export const RESELECT_PLAN = 'RESELECT_PLAN';
export const LOCK_USER_OUT = 'LOCK_USER_OUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const ROUTE_PUSH = 'ROUTE_PUSH';
export const ROUTE_POP = 'ROUTE_POP';
export const ROUTE_REPLACE = 'ROUTE_REPLACE';
export const URL_REDIRECT = 'URL_REDIRECT';
export const URL_REPLACE = 'URL_REPLACE';
export const SET_PAYPAL_REDIRECT_URLS = 'SET_PAYPAL_REDIRECT_URLS';
export const SET_IS_EXISTING_USER = 'SET_IS_EXISTING_USER';
export const PLAN_TAB_SELECTED = 'PLAN_TAB_SELECTED';
export const SAVE_INSTRUMENTATION_FLOW_NAME = 'SAVE_INSTRUMENTATION_FLOW_NAME';

// Add-on actions
export const SET_ADDONS = 'SET_ADDONS';
export const TOGGLE_ADDON = 'TOGGLE_ADDON';
export const REMOVE_ADDON = 'REMOVE_ADDON';
export const SET_ADDON_BUNDLES = 'SET_ADDON_BUNDLES';
export const TOGGLE_ADDON_BUNDLE = 'TOGGLE_ADDON_BUNDLE';
export const REMOVE_ADDON_BUNDLE = 'REMOVE_ADDON_BUNDLE';

// Constants from redux-localstorage.
export const REDUX_LOCALSTORAGE_INIT = 'redux-localstorage/INIT';

// Trial discount eligibility
export const SET_INELIGIBLE_TRIAL_DISCOUNT = 'SET_INELIGIBLE_TRIAL_DISCOUNT';

// Update assignment after usercreation
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT';

// Account creation recaptcha
export const USER_CREATION_RECAPTCHA = 'USER_CREATION_RECAPTCHA';

export const SET_USER_DATA = 'SET_USER_DATA';

export const BOOTSTRAP = {
  START: 'BOOTSTRAP_START',
  SUCCESS: 'BOOTSTRAP_SUCCESS',
  FAILURE: 'BOOTSTRAP_FAILURE',
};

export const CREATE_SUBSCRIBER = {
  START: 'CREATE_SUBSCRIBER_START',
  SUCCESS: 'CREATE_SUBSCRIBER_SUCCESS',
  FAILURE: 'CREATE_SUBSCRIBER_FAILURE',
  RESET: 'CREATE_SUBSCRIBER_RESET',
};

export const ACCOUNT_SUBMIT = {
  START: 'ACCOUNT_SUBMIT_START',
  SUCCESS: 'ACCOUNT_SUBMIT_SUCCESS',
  GENERAL_FAILURE: 'ACCOUNT_SUBMIT_GENERAL_FAILURE',
  PASSWORD_CHECK_FAILURE: 'ACCOUNT_SUBMIT_PASSWORD_CHECK_FAILURE',
};

export const GET_CONFIG = {
  START: 'GET_CONFIG_START',
  SUCCESS: 'GET_CONFIG_SUCCESS',
  FAILURE: 'GET_CONFIG_FAILURE',
  RESET: 'GET_CONFIG_RESET',
};

export const GET_FEATURE_FLAGS = {
  START: 'GET_FEATURE_FLAGS_START',
  SUCCESS: 'GET_FEATURE_FLAGS_SUCCESS',
  FAILURE: 'GET_FEATURE_FLAGS_FAILURE',
};

export const GET_COHORTS = {
  START: 'GET_COHORTS_START',
  SUCCESS: 'GET_COHORTS_SUCCESS',
  FAILURE: 'GET_COHORTS_FAILURE',
};

export const GET_SITE_CONFIG = {
  START: 'GET_SITE_CONFIG_START',
  SUCCESS: 'GET_SITE_CONFIG_SUCCESS',
  FAILURE: 'GET_SITE_CONFIG_FAILURE',
  RESET: 'GET_SITE_CONFIG_RESET',
};

export const GET_SPRINTV2_ELIGIBILITY = {
  START: 'GET_SPRINTV2_ELIGIBILITY',
  SUCCESS: 'GET_SPRINTV2_ELIGIBILITY_SUCCESS',
  FAILURE: 'GET_SPRINTV2_ELIGIBILITY_FAILURE',
  RESET: 'RESET_SPRINTV2_ELIGIBILITY',
};

export const GET_SPOTIFY_ELIGIBILITY = {
  START: 'GET_SPOTIFY_ELIGIBILITY',
  SUCCESS: 'GET_SPOTIFY_ELIGIBILITY_SUCCESS',
  FAILURE: 'GET_SPOTIFY_ELIGIBILITY_FAILURE',
  RESET: 'RESET_SPOTIFY_ELIGIBILITY',
};

// Ledger actions
export const GET_LEDGER = {
  START: 'GET_LEDGER',
  SUCCESS: 'GET_LEDGER_SUCCESS',
  FAILURE: 'GET_LEDGER_FAILURE',
  RESET: 'RESET_LEDGER',
};

// TODO WEB-24281: Cleanup after Hulu Ads Pref Experiment
// For WEB-24169 Ads Pref on Billing Experiment
// Alternate Ledger actions
export const GET_ALT_LEDGER = {
  START: 'GET_ALT_LEDGER',
  SUCCESS: 'GET_ALT_LEDGER_SUCCESS',
  FAILURE: 'GET_ALT_LEDGER_FAILURE',
  RESET: 'RESET_ALT_LEDGER',
};
export const SWITCH_ALT_LEDGER = 'SWITCH_ALT_LEDGER';
export const SWITCH_LEDGER = 'SWITCH_LEDGER';

// Actions for getting auth tokens.
export const GET_VENMO_TOKEN = {
  SUCCESS: 'GET_VENMO_TOKEN_SUCCESS',
  FAILURE: 'GET_VENMO_TOKEN_FAILURE',
};

export const GET_PARTNER_OFFER_CONTEXT = {
  START: 'GET_PARTNER_OFFER_CONTEXT_START',
  SUCCESS: 'GET_PARTNER_OFFER_CONTEXT_SUCCESS',
  FAILURE: 'GET_PARTNER_OFFER_CONTEXT_FAILURE',
};

export const GET_BUNDLE_PARTNER_PAYLOAD = {
  START: 'GET_BUNDLE_PARTNER_PAYLOAD_START',
  SUCCESS: 'GET_BUNDLE_PARTNER_PAYLOAD_SUCCESS',
  FAILURE: 'GET_BUNDLE_PARTNER_PAYLOAD_FAILURE',
};

export const GET_SUPERBUNDLE_ELIGIBILITY = {
  START: 'GET_SUPERBUNDLE_ELIGIBILITY_START',
  SUCCESS: 'GET_SUPERBUNDLE_ELIGIBILITY_SUCCESS',
  FAILURE: 'GET_SUPERBUNDLE_ELIGIBILITY_FAILURE',
};

export const GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY = {
  START: 'GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY_START',
  SUCCESS: 'GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY_SUCCESS',
  FAILURE: 'GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY_FAILURE',
};

export const CREATE_IDENTITY_VERIFICATION = {
  START: 'CREATE_IDENTITY_VERIFICATION_START',
  SUCCESS: 'CREATE_IDENTITY_VERIFICATION_SUCCESS',
  FAILURE: 'CREATE_IDENTITY_VERIFICATION_FAILURE',
};
