import * as types from '../constants/actionTypes';
import {
  getControlText,
  getTermsModalButtonText,
} from '../selectors/siteconfig';
import { showDefaultModal } from './modalActions';
import { selectAddons, selectAddonBundles } from './addonActions';
import { selectPlan } from './serverActions';
import * as MetricsEvents from '../metrics/metricsEvents';
import { getTabQueryParam } from '../selectors/flow';
import { getShowBundleTab } from '../selectors/plans';
import { ERROR, TAB_QUERY_BUNDLES_VALUE } from '../constants/routes';
import { BUNDLE_PLANS_TAB, PSEUDO_2P } from '../constants/plans';
import { updateCartAbandonmentCookie } from './cartAbandonmentActions';
import { replaceLocationQuery } from './urlActions';
import { routeReplace, routePush } from './routingActions';
import { getConfig } from './configActions';
import {
  runBootstrapFailure,
  runBootstrapStart,
  runBootstrapSuccess,
} from './bootstrapActions';
import { getIsLoggedIn } from '../selectors/user';
import * as routes from '../constants/routes';

// Action for handling planTabIndex in state
export const setSelectedPlanTab = tabIndex => ({
  type: types.PLAN_TAB_SELECTED,
  tabIndex,
});

export function shouldSkipPlanSelect(shouldSkip = false) {
  return {
    type: types.SHOULD_SKIP_PLAN_SELECT,
    shouldSkip,
  };
}

export function shouldShowPlanSelectMessage(shouldShow = false) {
  return {
    type: types.SHOULD_SHOW_PLAN_SELECT_MESSAGE,
    shouldShow,
  };
}

export function showPlanSelectTermsModal(title, body) {
  return (dispatch, getState) => {
    dispatch(
      showDefaultModal(title, body, getTermsModalButtonText(getState()))
    );
  };
}

export const updateSelectedPlan = plan => (
  dispatch,
  getState,
  { trackEvent }
) => {
  dispatch(selectPlan(plan));
  dispatch(selectAddons());
  dispatch(selectAddonBundles());

  updateCartAbandonmentCookie(getState());

  if (plan.identifier !== PSEUDO_2P) {
    trackEvent(MetricsEvents.SUBSCRIPTION_PLAN_SELECT);
  }
};

/**
 * TODO WEB-26131 : cleanup after Max Bundle Plan on Plan Select experiment
 *
 * This function is called when a user clicks the continue button on the
 * disclaimer modal after selecting the Max Bundle Plan on plan select.
 *
 * @param {} plan
 * @returns Dispatches event to either directs user to login or account info.
 */
export const selectMaxBundlePlan = plan => (dispatch, getState) => {
  const isLoggedIn = getIsLoggedIn(getState());

  dispatch(updateSelectedPlan(plan));

  if (!isLoggedIn) {
    return dispatch(routePush(routes.LOGIN));
  }

  return dispatch(routePush(routes.ACCOUNT_INFO));
};

/**
 * This function will trigger a user_interaction event with the options provided.
 */
export const trackUserInteractionEvent = options => (
  dispatch,
  getState,
  { trackEvent }
) => {
  trackEvent(MetricsEvents.USER_INTERACTION, options);
};

/**
 * The function will trigger an action to change the type of tab selected in the state
 * and also change the filteredPlans displayed
 * @param {String} index - this is the tab value
 * It does not return a value
 */
export const handlePlanTabSelect = index => {
  return (dispatch, getState) => {
    const {
      flow: { selectedPlanTab },
    } = getState();
    if (selectedPlanTab === index) {
      return;
    }

    dispatch(setSelectedPlanTab(index));
  };
};

/**
 * Checks if the required query param was used to start the flow and sets the
 * Bundle Plans Tab as active, unless the tabs are not available at all.
 * @returns {function(*, *): void} Redux-thunk action
 */
export const selectDefaultPlanTab = () => (dispatch, getState) => {
  const state = getState();
  const tabQueryParam = getTabQueryParam(state);
  const showBundleTab = getShowBundleTab(state);

  if (showBundleTab && tabQueryParam === TAB_QUERY_BUNDLES_VALUE) {
    dispatch(setSelectedPlanTab(BUNDLE_PLANS_TAB));
  }
};

const getBannerKey = programId => {
  const bannerKeysMap = {
    '600': 'live_banner',
    '18504': 'trio_premium_banner',
    '18999': 'pseudo_2p_banner',
  };
  return bannerKeysMap[programId];
};

/**
 * Launches Modal with Terms copy for Product Banner
 * It does not return a value
 */
export const handleBannerTermsClicked = programId => (dispatch, getState) => {
  const modalKey = `${getBannerKey(programId)}.modal`;
  const { title, message } = getControlText(getState(), modalKey);
  dispatch(showPlanSelectTermsModal(title, message));
};

/**
 * Routes the user into the 2P HISUF flow
 * It does not return a value
 */
export const handleBannerPlanSelected = programId => (dispatch, getState) => {
  const key = `${getBannerKey(programId)}.cta`;
  const { param, value } = getControlText(getState(), key);
  dispatch(replaceLocationQuery({ [param]: value }));
  return dispatch(refetchConfig());
};

export const refetchConfig = () => {
  return dispatch =>
    Promise.resolve()
      .then(() => dispatch(runBootstrapStart()))
      .then(() => dispatch(getConfig()))
      .then(() => dispatch(runBootstrapSuccess()))
      .catch(error => {
        dispatch(routeReplace(ERROR));
        return dispatch(runBootstrapFailure(error));
      });
};
