/**
 * Obtain the environment value to be used by the Hulu Identity feature components in the web-login-ui package.
 */
export const getIdentityEnvironment = () => {
  let sufoReduxEnvironment = process.env.HULU_ENV || process.env.NODE_ENV;

  /**
   * Both the smoke and production deployments of the application use the same production build from Jenkins.
   * This is necessary because the smoke and production environments share the same Akamai NetStorage space
   * to store static assets.
   * (See Jenkinsfile)
   * Otherwise, if a different build of one release (of the same git commit hash) is deployed to a second environment,
   * this build will override the previous build of the same release deployed to a different environment,
   * essentially causing the smoke and production environments to "override each other".
   *
   * Thus, the smoke and production deployments have the same environment variables baked into the build at build-time,
   * as sufo-redux is a pure client-side React app with no server runtime.
   *
   * It's unable to differentiate the smoke environment from the build itself, so we need to look at the URL
   * as a temporary solution due to the limitation of the architecture.
   */
  if (window.location.hostname.includes('signup-smoke')) {
    sufoReduxEnvironment = 'smoke';
  }

  const webLoginUiEnvironment = {
    development: 'dev',
    staging: 'staging',
    dogfood: 'staging',
    smoke: 'smoke',
    production: 'prod',
  }[sufoReduxEnvironment];

  return webLoginUiEnvironment;
};
