import { connect } from 'react-redux';
import { clearPrefillBillingInfo } from '../actions/serverActions';
import CreditCardForm from '../components/CreditCardForm';
import * as paymentMethods from '../constants/paymentMethods';
import { getIsAmazonEchoShowClient } from '../selectors/flow';
import { getPaymentType } from '../selectors/subscription';
import { getIsValidatePaymentZipFeatureEnabled } from '../selectors/featureFlag';

export default connect(
  state => ({
    isPrefilled: getPaymentType(state) === paymentMethods.USE_CURRENT,
    displayPlaceHolder: getIsAmazonEchoShowClient(state),
    isValidateZipEnabled: getIsValidatePaymentZipFeatureEnabled(state),
  }),
  dispatch => ({
    dispatch,
  }),
  (stateProps, dispatchProps) => ({
    ...stateProps,
    onPrefilledFieldsChanged: () => {
      const { dispatch } = dispatchProps;
      if (stateProps.isPrefilled) {
        dispatch(clearPrefillBillingInfo());
      }
    },
  })
)(CreditCardForm);
