import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { preventFocus } from '../utils/a11y';
import { NOAH, PSEUDO_2P, BUNDLE_PLANS } from '../constants/plans';
import NoahDisclaimerLink from './NoahDisclaimerLink';
import { isSashLiveDisneyBundle, isSashDisneyBundle } from '../selectors/plan';

class PlanDisclaimer extends Component {
  constructor(props) {
    super(props);

    this.handleTermsClicked = this.handleTermsClicked.bind(this);
  }

  handleTermsClicked(e) {
    e.preventDefault();

    return this.props.openModal();
  }

  render() {
    const {
      disclaimer,
      identifier,
      isInteractive,
      noahTooltipMessage,
      learnMoreUrl,
      planIncludesLive,
      isDisneySuperBundle,
      isNOAHIncluded,
      showLiveTvTrial,
      liveTvTrialDisclaimer,
      showValuePropBullets,
      showFlashSalePlan,
      isHuluFreeTrialsEnabled,
    } = this.props;
    const showMonthlySavingsDisclaimer =
      isSashDisneyBundle(identifier) && showValuePropBullets;
    const showNoFreeTrialAvailable =
      isSashLiveDisneyBundle(identifier) &&
      !showLiveTvTrial &&
      !isHuluFreeTrialsEnabled;
    const showBundleTermsApply = BUNDLE_PLANS.includes(identifier);
    let contents = null;
    const showStandaloneTermsApply =
      identifier !== NOAH && !showBundleTermsApply;

    const isPseudo2PBundle = identifier === PSEUDO_2P;
    const pseudo2PLearnMoreUrl = 'https://www.hulu.com/disney-bundle-duo-basic';

    const getTermsApplyText = () => {
      if (showBundleTermsApply) return 'Bundle Terms Apply';

      if (showStandaloneTermsApply) return 'Terms Apply';

      return '';
    };

    const termsApplyText = getTermsApplyText();

    const termsApply = (
      <button
        type="button"
        className="plan__terms"
        tabIndex={isInteractive ? 0 : -1}
        onMouseDown={preventFocus}
        onClick={this.handleTermsClicked}
      >
        {termsApplyText}
      </button>
    );

    if (
      showFlashSalePlan ||
      planIncludesLive ||
      isNOAHIncluded ||
      isDisneySuperBundle ||
      isPseudo2PBundle
    ) {
      contents = (
        <>
          {showLiveTvTrial && (
            <div className="plan__live-tv-trial">{liveTvTrialDisclaimer}</div>
          )}
          {showNoFreeTrialAvailable && (
            <div className="plan__no-free-trail">No free trial available.</div>
          )}
          {showMonthlySavingsDisclaimer && (
            <div className="plan__monthly-savings">
              *Savings compared to regular monthly price of each service.
            </div>
          )}
          <div
            className={classNames('plan__terms__container', {
              'plan__terms__container--dplus': isDisneySuperBundle,
            })}
          >
            {isNOAHIncluded && noahTooltipMessage && (
              <NoahDisclaimerLink
                disclaimer={disclaimer}
                isInteractive={isInteractive}
                tooltipMessage={noahTooltipMessage}
              />
            )}
            {termsApplyText && termsApply}
          </div>
          {(isDisneySuperBundle || isPseudo2PBundle) && (
            <div className="plan__more-details__container">
              <a
                href={isPseudo2PBundle ? pseudo2PLearnMoreUrl : learnMoreUrl}
                target="_blank"
                rel="noreferrer"
                className="plan__more-details"
              >
                Learn More
              </a>
            </div>
          )}
        </>
      );
    } else if (disclaimer) {
      contents = disclaimer;
    }

    return (
      contents && (
        <div
          className={classNames(
            'plan__disclaimer',
            showLiveTvTrial && `plan__disclaimer--${identifier}`
          )}
        >
          {contents}
        </div>
      )
    );
  }
}

const propTypes = {
  disclaimer: PropTypes.string,
  identifier: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool,
  noahTooltipMessage: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  learnMoreUrl: PropTypes.string,
  planIncludesLive: PropTypes.bool.isRequired,
  isDisneySuperBundle: PropTypes.bool.isRequired,
  isNOAHIncluded: PropTypes.bool.isRequired,
  showLiveTvTrial: PropTypes.bool,
  liveTvTrialDisclaimer: PropTypes.string,
  showValuePropBullets: PropTypes.bool,
  showFlashSalePlan: PropTypes.bool,
  isHuluFreeTrialsEnabled: PropTypes.bool,
};

PlanDisclaimer.propTypes = propTypes;

export const PlanDisclaimerPropTypes = PropTypes.shape(propTypes);

export default PlanDisclaimer;
