module.exports = {
  url: {
    yokozuna: '/api/v2/',
    yokozuna_v3: '/api/v3/',
    yokozuna_v4: '/api/v4/',
    hoth: {
      host: 'https://auth.huluqa.com',
      login: '/web/login',
    },
    flex_v2: 'https://edge-api.huluqa.com',
    site: 'https://www.huluqa.com',
    login: 'https://secure.huluqa.com/account',
    changePlan: 'https://secure.huluqa.com/account',
    redeemGiftCode: 'https://secure.huluqa.com/start/gifting_redeem',
    onboardingPage: 'https://www.huluqa.com/onboarding',
    forgotPassword: 'https://secure.huluqa.com/users/find_account',
    tosText: 'https://www.huluqa.com/terms.txt',
    tosHtml: 'https://www.huluqa.com/terms',
    privacyText: 'https://www.huluqa.com/privacy.txt',
    privacyHtml: 'https://www.huluqa.com/privacy',
    privacyHtmlTWDC:
      'https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/',
    support: 'https://help.huluqa.com/contact',
    dplus: 'https://qa-web.disneyplus.com',
    activate: 'https://secure.huluqa.com/account/activations',
    sheerId: 'https://offers.sheerid.com/hulu/staging/student/',
    websub: 'https://secure.huluqa.com/subscription',
    cup: 'https://secure.huluqa.com/commerce',
    logoutApi: 'https://secure.huluqa.com/api/2.0/logout',
    disneyFOC:
      'https://privacy.thewaltdisneycompany.com/en/definitions/#The-Walt-Disney-Family-of-Companies',
  },
  recaptcha: {
    authentication: {
      publicKey: '6LfB3jkbAAAAAM124pQdh8VcmVJy417NjzjpVmAW',
    },
    userCreation: {
      publicKey: '6LfB3jkbAAAAAM124pQdh8VcmVJy417NjzjpVmAW',
    },
  },
  spotify: {
    clientId: '9f00faf0e3d74e9d97654a0c4b465ec2',
    testModeAllowed: true,
  },
  newRelicConfig: {
    applicationID: '29654697',
  },
  paypal: {
    environment: 'sandbox',
    merchantId: 'FRKJQBYUTV2P2',
  },
  cookieDomain: '.huluqa.com',
  siteConfig: {
    params: 'env=staging',
  },
  tealiumEnv: 'qa',
};
