import { triggerReload } from './bootstrapActions';
import { routeReplace } from './routingActions';
import * as types from '../constants/actionTypes';
import * as modals from '../constants/modals';
import { ROOT } from '../constants/routes';
import { getControlText } from '../selectors/siteconfig';

const changeModal = modal => {
  return {
    type: types.CHANGE_MODAL,
    modal,
  };
};

export const closeModal = () => {
  return (dispatch, getState) => {
    const {
      modal: { preventClose, redirectRoute, shouldTriggerReload },
    } = getState();
    if (preventClose) {
      return;
    }

    dispatch(changeModal({}));

    if (redirectRoute) {
      dispatch(routeReplace(redirectRoute));
    }

    if (shouldTriggerReload) {
      dispatch(triggerReload());
    }
  };
};

export const showDefaultModal = (title, message, buttonText) => {
  return changeModal({
    type: modals.DEFAULT,
    title,
    message,
    buttonText,
  });
};

export const showTermsModal = (message, fitContent) => {
  return changeModal({
    type: modals.TERMS,
    message,
    fitContent,
  });
};

// when user endpoint fails a catchall failure modal appears
export const showUserEndpointFailureModal = () => {
  return changeModal({
    type: modals.ERROR,
    message:
      'We could not complete your request right now. Please try again later.',
    redirectRoute: ROOT,
    shouldTriggerReload: true,
  });
};

// when ledger endpoint fails, we should show error and then hard redirect to 3-up plan select
export const showLedgerEndpointFailureModal = () => {
  return changeModal({
    type: modals.ERROR,
    message:
      'We could not complete your request right now. Please try again later.',
    redirectRoute: ROOT,
    shouldTriggerReload: true,
  });
};

// when the app fails to redirect to the Sheer ID verification page
// because the user is not logged in yet.
export const showStudentVerificationFailureModal = () => {
  return changeModal({
    type: modals.ERROR,
    preventClose: false,
    message:
      'We could not complete your request right now. Please try again later.',
    redirectRoute: ROOT,
    shouldTriggerReload: true,
  });
};

export const showLockoutModal = () => {
  return changeModal({
    type: modals.ERROR,
    preventClose: true,
    message:
      "We're sorry, you're not eligible to create an account at this time.",
  });
};

export const showStudentModal = () => (dispatch, getState) => {
  dispatch(
    changeModal({
      type: modals.STUDENT,
      title: getControlText(getState(), 'student_modal.title'),
      message: getControlText(getState(), 'student_modal.message'),
      acceptText: getControlText(getState(), 'student_modal.accept'),
      declineText: getControlText(getState(), 'student_modal.decline'),
    })
  );
};

export const showUnifiedIdentityLearnMoreModal = () => dispatch => {
  dispatch(
    changeModal({
      type: modals.LEARN_MORE_MODAL,
    })
  );
};

// TODO WEB-26131 : cleanup after Max Bundle on Plan Select experiment
export const showMaxBundleSelectModal = plan => (dispatch, getState) => {
  dispatch(
    changeModal({
      type: modals.DEFAULT,
      title: getControlText(getState(), 'max_disclaimer_modal.title'),
      message: getControlText(getState(), 'max_disclaimer_modal.message'),
      acceptText: getControlText(getState(), 'max_disclaimer_modal.accept'),
      submitData: {
        submitAction: 'maxBundleSelect',
        plan,
      },
    })
  );
};
