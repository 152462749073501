import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getIsSuperbundleFlow,
  getIsMaxBundleFlow,
  getIsDuoBundleFlow,
  getIsInLiveBundleLogoExperiment,
} from '../selectors/user';

const SuperBundleLogo = ({
  enabled,
  shouldDisplayMaxBundleLogo,
  shouldDisplayDuoBundleLogo,
  shouldDisplayLiveBundleLogo,
}) => {
  if (shouldDisplayMaxBundleLogo) {
    return (
      <img
        className="superbundle-logo"
        src="/static/images/disney-hulu-max-bundle-logo.svg"
        alt="Disney+, Hulu, Max Bundle"
      />
    );
  }
  if (shouldDisplayDuoBundleLogo) {
    return (
      <img
        className="superbundle-logo"
        src="/static/images/disney-hulu-bundle-logo.svg"
        alt="Hulu & Disney+ logos"
      />
    );
  }
  // TODO WEB-24424: cleanup LiveTV Logo experiment code
  if (shouldDisplayLiveBundleLogo) {
    return (
      <img
        className="superbundle-logo"
        src="/static/images/live-tv-bundle-logo.svg"
        alt="Hulu Live TV logos"
      />
    );
  }
  if (enabled) {
    return (
      <img
        className="superbundle-logo"
        src="/static/images/disney-hulu-espn-bundle-logo.svg"
        alt="Hulu, Disney+, ESPN+ logos"
      />
    );
  }

  return null;
};

SuperBundleLogo.propTypes = {
  enabled: PropTypes.bool,
  shouldDisplayMaxBundleLogo: PropTypes.bool,
  shouldDisplayDuoBundleLogo: PropTypes.bool,
  shouldDisplayLiveBundleLogo: PropTypes.bool,
};

const mapStateToProps = state => ({
  enabled: getIsSuperbundleFlow(state),
  shouldDisplayMaxBundleLogo: getIsMaxBundleFlow(state),
  shouldDisplayDuoBundleLogo: getIsDuoBundleFlow(state),
  shouldDisplayLiveBundleLogo: getIsInLiveBundleLogoExperiment(state),
});

export default connect(mapStateToProps)(SuperBundleLogo);
