import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TOOLTIP_TOGGLE_KEYS } from '../constants/keyboardKeys';
import { WEB_24169 } from '../constants/experiments';
import { getAltLedgerCopy } from '../selectors/altLedger';

require('../styles/ledgerAdPreference.scss');

// TODO WEB-24281: Cleanup after Hulu Ads Pref Experiment

const Treatment1Toggle = ({ adsPrefCopy, action, toggleInitialState }) => {
  const { planName, priceDiff, disclaimer, details } = adsPrefCopy;

  const [toggleState, setToggleState] = useState(toggleInitialState);

  const handleSwitch = () => {
    setToggleState(prevToggle => !prevToggle);
    action();
  };

  return (
    <div className="ledger__ad_preference ledger__ad_preference__treatment-1">
      <span className="plan-copy-wrapper">
        <span className="plan-upgrade">UPGRADE</span>
        <div className="plan-name">{planName}</div>
        {priceDiff && (
          <div className="price-diff">
            {toggleState ? 'Selected' : priceDiff}
          </div>
        )}
        {disclaimer && <div className="disclaimer">{disclaimer}</div>}
        {details && !toggleState && <div className="disclaimer">{details}</div>}
      </span>
      <label className="toggle">
        <input
          className="toggle-input"
          onChange={handleSwitch}
          type="checkbox"
          checked={toggleState}
        />
        <span className="toggle-span" />
      </label>
    </div>
  );
};

const Treatment2TextLink = ({ adsPrefCopy, action }) => {
  const { planName, priceDiff, disclaimer } = adsPrefCopy;

  const handleKeyDown = keyDownAction => e => {
    if (TOOLTIP_TOGGLE_KEYS.includes(e.key)) {
      keyDownAction();
    }
  };

  return (
    <div className="ledger__ad_preference ledger__ad_preference__treatment-2">
      <a
        className="hyperlink-switch-button"
        tabIndex="0"
        role="button"
        onClick={action}
        onKeyDown={handleKeyDown(action)}
      >
        {`Switch to ${planName}`}
      </a>
      {disclaimer && <div className="disclaimer">{disclaimer}</div>}
      {priceDiff && <div className="price-diff">{priceDiff}</div>}
    </div>
  );
};

const treatmentPropTypes = {
  adsPrefCopy: PropTypes.shape({
    planName: PropTypes.string,
    priceDiff: PropTypes.string,
    disclaimer: PropTypes.string,
    details: PropTypes.string,
  }),
  action: PropTypes.func,
};

Treatment2TextLink.propTypes = treatmentPropTypes;
Treatment1Toggle.propTypes = {
  ...treatmentPropTypes,
  toggleInitialState: PropTypes.bool,
};

/**
 * For WEB-24169 Ads Pref on Billing Experiment
 *
 * This component offers the users a CTA to be able to switch between the Ads or No Ads version
 * of the selected plan. Clicking the CTA will update the Ledger to display the newly selected plan,
 * and when the user submits the payment form, that new plan will be used.
 *
 * Treatment 1 - the user sees a toggle CTA to switch plans
 * Treatment 2 - the user sees a hyperlinked text CTA to switch plans
 *
 * @param {*} treatment - Treatment ID for the Experiment
 * @param {*} onSwitchLedgers - function to trigger the switching of ledger and altLedger values
 * @param {*} altLedgerCopy - the copy to display in alt ledger section
 */
const LedgerAdPreference = ({ treatment, onSwitchLedgers, altLedgerCopy }) => {
  const { alternatePlanCopy, currentPlanCopy } = altLedgerCopy;

  const noAdsPlanCopy = alternatePlanCopy?.priceDiff
    ? alternatePlanCopy
    : currentPlanCopy;

  const [adsPrefCopy, setAdsPrefCopy] = useState(alternatePlanCopy);
  const [altAdsPrefCopy, setAltAdsPrefCopy] = useState(currentPlanCopy);

  const isTreatmentOne = treatment === WEB_24169.TREATMENT1;
  const isTreatmentTwo = treatment === WEB_24169.TREATMENT2;

  // Click handler for both experiment treatments
  const handleClick = () => {
    onSwitchLedgers();
    setAltAdsPrefCopy(adsPrefCopy);
    setAdsPrefCopy(altAdsPrefCopy);
  };

  return (
    <>
      {isTreatmentOne && noAdsPlanCopy && (
        // TODO - WEB-24283: show treatment 1 UI
        <Treatment1Toggle
          adsPrefCopy={noAdsPlanCopy}
          action={handleClick}
          toggleInitialState={!alternatePlanCopy?.priceDiff}
        />
      )}
      {isTreatmentTwo && adsPrefCopy && (
        <Treatment2TextLink adsPrefCopy={adsPrefCopy} action={handleClick} />
      )}
    </>
  );
};

LedgerAdPreference.propTypes = {
  treatment: PropTypes.string,
  onSwitchLedgers: PropTypes.func,
  altLedgerCopy: PropTypes.object,
};

const mapStateToProps = state => ({
  altLedgerCopy: getAltLedgerCopy(state),
});

export default connect(mapStateToProps, null)(LedgerAdPreference);
