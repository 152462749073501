import { datadogRum } from '@datadog/browser-rum';
import { connect } from 'react-redux';

import {
  closeModal,
  showStudentVerificationFailureModal,
} from '../actions/modalActions';
import { urlRedirect } from '../actions/routingActions';
import Modal from '../components/Modal';
import * as modals from '../constants/modals';
import * as routes from '../constants/routes';
import { getControlText } from '../selectors/siteconfig';
import { getIsLoggedIn } from '../selectors/user';
import { redirectToIdentityPartner } from '../actions/identityActions';
import { selectMaxBundlePlan } from '../actions/planSelectActions';

function mapStateToProps(state) {
  const { redirectText, redirectRoute, ...modal } = state.modal;
  return {
    ...modal,
    redirectText:
      redirectText ||
      getControlText(state, 'billing_page.error_modal.help_text'),
    redirectRoute:
      redirectRoute ||
      getControlText(state, 'billing_page.error_modal.help_url'),
    isLoggedIn: getIsLoggedIn(state),
  };
}

function mergeProps(stateProps, dispatchProps) {
  const { dispatch } = dispatchProps;
  const { type, isLoggedIn } = stateProps;

  return {
    ...stateProps,
    closeModal: () => {
      if (type === modals.STUDENT) {
        dispatch(urlRedirect(routes.GO_ONE_HULU));
      } else {
        dispatch(closeModal());
      }
    },
    submitModal: submitData => {
      if (type === modals.STUDENT) {
        if (isLoggedIn) {
          dispatch(redirectToIdentityPartner());
        } else {
          dispatch(showStudentVerificationFailureModal());
          datadogRum.addError(
            new Error(
              'Student verification initiated before the user is logged in.'
            )
          );
        }
        // TODO WEB-26131 : cleanup after Max Bundle on Plan Select experiment
      } else if (type === modals.DEFAULT) {
        if (submitData) {
          const { submitAction } = submitData;

          switch (submitAction) {
            case 'maxBundleSelect':
              dispatch(selectMaxBundlePlan(submitData.plan));
              dispatch(closeModal());
              break;
            default:
              break;
          }
        }
      }
    },
  };
}

export default connect(mapStateToProps, null, mergeProps)(Modal);
