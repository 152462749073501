import get from 'lodash/get';

import * as types from '../constants/actionTypes';
import * as api from '../api/Ledger';
import { getV2LedgerRequest, getLedger } from '../selectors/ledger';
import { getSelectedPlan } from '../selectors/user';
import { findPlanById, getPlans } from '../selectors/plans';
import { getConfigRequest } from '../selectors/config';
import { fetchConfig } from '../api/ConfigApi';
import { switchLedger } from './ledgerActions';
import { selectPlan } from './serverActions';
import { getAltLedger } from '../selectors/altLedger';

// TODO WEB-24281: Cleanup after Hulu Ads Pref Experiment

/**
 * This object represents the alternate plan (ads/no-ads) for each plan option.
 * The key is the current plan and the value is the alternate plan.
 */
const alternatePlans = {
  '1': 2, // SASH -> NOAH
  '2': 1, // NOAH -> SASH

  '25': 26, //  HULU_DISNEY_ADS_2P_BUNDLE -> 2P_BUNDLE_PREMIUM
  '26': 25, //  2P_BUNDLE_PREMIUM -> HULU_DISNEY_ADS_2P_BUNDLE

  '23': 21, //  ADS_HULU_DISNEY_ESPN -> NOADS_HULU_DISNEY_ESPN
  '21': 23, //  NOADS_HULU_DISNEY_ESPN -> ADS_HULU_DISNEY_ESPN

  '24': 22, //  ADS_LIVE_DISNEY_ESPN -> NOADS_LIVE_DISNEY_ESPN
  '22': 24, //  NOADS_LIVE_DISNEY_ESPN -> ADS_LIVE_DISNEY_ESPN

  '28': 29, //  ADS_MAX_HULU_DISNEY -> NOADS_MAX_HULU_DISNEY
  '29': 28, //  NOADS_MAX_HULU_DISNEY -> ADS_MAX_HULU_DISNEY
};

/**
 * Alternate Ledger API actions
 * Created for WEB-24169 Ads Pref on Billing Experiment
 *
 * This function calls the Yokozuna Ledger API to get the
 * alternate plan for a user depending on their selected plan.
 * The alternate plan will be the ads or no-ads version of the selected plan.
 */
export function getAlternateLedger() {
  return async (dispatch, getState) => {
    dispatch(resetAltLedger());
    dispatch(getAltLedgerStart());

    // Grab the current selected plan from redux state
    // and generate the ledgerRequest for that plan.
    const currSelectedPlan = getSelectedPlan(getState());
    const ledgerRequest = getV2LedgerRequest(getState());

    // Find the corresponding alternate plan ID
    const alternatePlanId = alternatePlans[currSelectedPlan.id];
    if (!alternatePlanId) {
      dispatch(getAltLedgerFailure());
      return Promise.resolve();
    }

    // Search plans in redux state to see if the alternate plan
    // is available to the user
    let alternatePlan = findPlanById(alternatePlanId, getPlans(getState()));

    // If the alternate plan is not available in redux state,
    // call Yokozuna config endpoint to get all plans,
    // then search again
    if (!alternatePlan) {
      // Generate the default config request body
      const configRequest = getConfigRequest(getState());

      // Depending on the current plan, we will edit the config request body
      // so that we will receive the alternate plan in the response.
      if (currSelectedPlan.identifier === 'ADS_MAX_HULU_DISNEY') {
        configRequest.from = 'disney-hulu-max-bundle';
      } else if (currSelectedPlan.identifier === 'NOADS_MAX_HULU_DISNEY') {
        configRequest.from = 'disney-hulu-max-bundle-basic';
      } else {
        configRequest.programId = undefined;
      }

      try {
        // Re-query for the config data using the updated request body
        const configResponse = await fetchConfig(configRequest);

        // Search for the alternate plan in the config response
        alternatePlan = findPlanById(alternatePlanId, configResponse.plans);

        if (!alternatePlan) {
          dispatch(getAltLedgerFailure());
          return Promise.resolve();
        }
      } catch (error) {
        dispatch(getAltLedgerFailure(error));
        return Promise.resolve();
      }
    }

    // Generate the request body to grab the alternate plan's ledger
    // Use the current plan's data, but update the subscription.plan
    // to use the alternate plan's data
    const newLedgerRequest = {
      ...ledgerRequest,
      subscription: {
        addOns: ledgerRequest.subscription.addOns,
        addOnBundles: ledgerRequest.subscription.addOnBundles,
        promotion: ledgerRequest.subscription.promotion,
        plan: {
          id: alternatePlan.id,
          componentIds: alternatePlan.componentIds,
          policyId: alternatePlan.subscription.policyId,
          programId: alternatePlan.subscription.promotion.programId,
          discountIds: alternatePlan.subscription.discountId
            ? [alternatePlan.subscription.discountId]
            : [],
        },
      },
    };

    // Call yokozuna and handle the response
    // If successful, set altLedger and altSelectedPlan values in redux state
    // If error, reject
    return api.getV2Ledger(newLedgerRequest).then(
      response => {
        dispatch(getAltLedgerSuccess(response));
        dispatch(selectAltPlan(alternatePlan));
      },
      error => {
        dispatch(getAltLedgerFailure(error));
        return Promise.reject(error);
      }
    );
  };
}

export function getAltLedgerSuccess(payload) {
  return {
    type: types.GET_ALT_LEDGER.SUCCESS,
    payload,
  };
}

export function getAltLedgerStart() {
  return {
    type: types.GET_ALT_LEDGER.START,
  };
}

export function getAltLedgerFailure(error) {
  return {
    type: types.GET_ALT_LEDGER.FAILURE,
    payload: error,
  };
}

export function resetAltLedger() {
  return {
    type: types.GET_ALT_LEDGER.RESET,
  };
}

// Call the Switch Alt Ledger action and pass in the new altLedger value
export function switchAltLedgers(defaultLedger) {
  return {
    type: types.SWITCH_ALT_LEDGER,
    newAltLedger: defaultLedger,
  };
}

/**
 * This function grabs the ledger and altLedger objects from the
 * redux state and swaps the contents of the two objects.
 */
export function switchLedgerAndAlternateLedger() {
  return async (dispatch, getState) => {
    const defaultLedger = getLedger(getState());
    const altLedger = getAltLedger(getState());

    dispatch(switchAltLedgers(defaultLedger));
    dispatch(switchLedger(altLedger));
  };
}

export function selectAltPlan(plan) {
  return {
    type: types.SELECT_ALT_PLAN,
    plan,
  };
}

/**
 * This function grans the selectedPlan and the altSelected plan
 * from the user object in redux state and swaps the contents
 * of the two objects.
 */
export function switchSelectedPlanAndAltSelectedPlan() {
  return async (dispatch, getState) => {
    const currSelectedPlan = get(getState(), 'user.selectedPlan');
    const currAltSelectedPlan = get(getState(), 'user.altSelectedPlan');

    dispatch(selectPlan(currAltSelectedPlan));
    dispatch(selectAltPlan(currSelectedPlan));
  };
}
