import get from 'lodash/get';
import merge from 'lodash/merge';

import env from '../../../config/env';
import {
  GET_SITE_CONFIG,
  GET_LEDGER,
  GET_ALT_LEDGER,
} from '../constants/actionTypes';
import {
  replaceKeys,
  recursivelyReplaceReplacementVar,
} from '../utils/textUtils';
import {
  PARTNER_TEXT_KEY_ROOT,
  PARTNER_DATA_KEY,
  PARTNER_KEY_PREFIX,
  URL_KEY_PREFIX,
  LINE_BREAK_KEY,
  LINE_BREAK_HTML_KEY,
} from '../constants/siteConfigKeys';
import { getPartnerOfferKey } from '../selectors/partners';

// TextMiddleware is used to make text manipulations to actions.
export const textMiddleware = store => next => action => {
  const currentActionType = action && action.type;

  if (currentActionType === GET_SITE_CONFIG.SUCCESS) {
    const partnerOfferKey = getPartnerOfferKey(store.getState());

    // Merge default and custom text nodes for partners
    mergePartnerNodes(action, partnerOfferKey);

    // Replace all partner replacement strings in site-config partner node with mappings from data node.
    if (action.payload[PARTNER_TEXT_KEY_ROOT]) {
      const partnerData = get(
        action.payload[PARTNER_TEXT_KEY_ROOT],
        PARTNER_DATA_KEY
      );
      action.payload = replaceKeys(
        action.payload,
        partnerData,
        PARTNER_KEY_PREFIX
      );
    }

    // Replace URL environment variables
    action.payload = replaceKeys(action.payload, env.url, URL_KEY_PREFIX);

    // Replace all delimited keys in site_config with mappings
    action.payload = replaceKeys(action.payload, {
      // Replace plan names
      ...get(action.payload, 'sufo_plans'),

      // Replace %BR% with escaped newline string
      [LINE_BREAK_KEY]: '\\n\\n',

      [LINE_BREAK_HTML_KEY]: '<br>',
    });
  } else if (
    currentActionType === GET_LEDGER.SUCCESS ||
    currentActionType === GET_ALT_LEDGER.SUCCESS
  ) {
    action.payload = recursivelyReplaceReplacementVar(action.payload);
  }

  return next(action);
};

export const mergePartnerNodes = (action, partnerOfferKey) => {
  const partnerTextNodeDefaultKey = `${PARTNER_TEXT_KEY_ROOT}_default`;

  if (partnerOfferKey) {
    // Merge default and custom text nodes for partners
    const partnerTextNodeCustomKey = `${PARTNER_TEXT_KEY_ROOT}_${partnerOfferKey}`;
    const partnerTextNodeCustom = get(action.payload, partnerTextNodeCustomKey);

    // Only merge partner nodes if custom text node exists
    if (partnerTextNodeCustom) {
      const partnerTextNodeDefault = get(
        action.payload,
        partnerTextNodeDefaultKey
      );
      action.payload[PARTNER_TEXT_KEY_ROOT] = merge(
        {},
        partnerTextNodeDefault,
        partnerTextNodeCustom
      );
    }
    delete action.payload[partnerTextNodeCustomKey];
  }

  delete action.payload[partnerTextNodeDefaultKey];
};
